import { LocationService } from './../../../../core/services/location.service';
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MaskitoElementPredicateAsync, MaskitoOptions } from '@maskito/core';
import { TranslateService } from '@ngx-translate/core';
import { SCREEN_NAME_BY_COMPONENT_NAME } from 'src/app/core/constants/url-screenname.constant';
import { OrbitFormValidators } from 'src/app/core/helper/class/form-validators';
import { ArpaInputComponent } from 'src/app/core/helper/components/arpa-input/arpa-input.component';
import { UserAgreementComponent } from 'src/app/core/helper/components/user-agreement/user-agreement.component';
import { AnalyticEvents } from 'src/app/core/models/enums/analytic-events';
import { Message_Type } from 'src/app/core/models/enums/message-type';
import { ComponentType } from 'src/app/core/models/enums/phone-input-component-types';
import { StorageKeys } from 'src/app/core/models/enums/storage-keys';
import { IButtonOpts, ListModalModel } from 'src/app/core/models/interfaces/modal-components-models';
import { IRegisterModelV2 } from 'src/app/core/models/interfaces/register-model';
import { AccountService } from 'src/app/core/services/account.service';
import { AnalyticService } from 'src/app/core/services/analytic.service';
import { ClientAlertService } from 'src/app/core/services/client-alert.service';
import { FarmService } from 'src/app/core/services/farm.service';
import { IntlPhoneInputService } from 'src/app/core/services/intl-phone.service';
import { LanguageService } from 'src/app/core/services/lang.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { OnboardingStorageService } from 'src/app/core/services/onboarding-storage.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { environment } from 'src/environments/environment';

type RegisterStateTypes = "namesurname" | "phone" | "password"

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss', './../../auth.page.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RegisterComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() onRegister: EventEmitter<any> = new EventEmitter<any>()
  @Input() idPrefix: string
  binding

  registerStates: RegisterStateTypes = "namesurname"

  registerForm: FormGroup<{
    namesurname: FormControl<string>,
    phone: FormControl<string>,
    email: FormControl<string>,
    password: FormControl<string>,
    // passwordCheck: FormControl<string>,
    refCode: FormControl<string>,
    agreementConfirmation: FormControl<boolean>
  }>;
  countryCodes: string = ""
  componentType = ComponentType.FULL_INPUT_SEPERATED
  passwordVisibility: boolean = false
  @Input() socialUserInfo;
  socialType: "facebook" | "apple" | "google";
  socialToken: string;
  isPhoneValid: boolean = true

  constructor(
    private formBuilder: UntypedFormBuilder,
    private alertService: ClientAlertService,
    private translate: TranslateService,
    private analyticService: AnalyticService,
    private farmService: FarmService,
    private modalService: ModalService,
    private toastService: ToastService,
    private router: Router,
    private translateService: TranslateService,
    private accountService: AccountService,
    private changeDetRef: ChangeDetectorRef,
    private loading: LoadingService,
    private storageService: StorageService,
    private onboardingStorageService: OnboardingStorageService,
    private langService: LanguageService,
    private intlPhoneService: IntlPhoneInputService
  ) {
    let rawList = translateService.getBrowserCultureLang().split("-")
    let _countryCodes = "us";
    if (rawList.length > 1) {
      let code = rawList[1].toLowerCase();
      _countryCodes = code + "," + _countryCodes
    } else {
      _countryCodes = rawList[0] + "," + _countryCodes
    }

    if (LanguageService.CountryCodeFromIP && LanguageService.CountryCodeFromIP !== '') {
      this.countryCodes = LanguageService.CountryCodeFromIP + "," + this.countryCodes

      this.generateMaskAndPlaceholder()
    }
    else
      LocationService.GetLocationFromIp().then(res => {
        if (res?.countryCode)
          _countryCodes = res.countryCode.toLowerCase() + "," + _countryCodes
        this.countryCodes = _countryCodes

        this.generateMaskAndPlaceholder()
      }).catch(err => {
        this.countryCodes = _countryCodes

        this.generateMaskAndPlaceholder()
      })
    // if (LanguageService.CountryCodeFromIP && LanguageService.CountryCodeFromIP !== '')
    //   this.countryCodes = LanguageService.CountryCodeFromIP + "," + this.countryCodes

  }
  ngOnDestroy(): void {

    // this.registerForm.removeControl("refCode")

    this.registerForm = null
    console.log("register destroyed")
  }

  userAgreementTemplate = "<a class='btn_userAgreement'>" + this.translateService.instant("general.userAgreement") + "</a>"
  kvkkTemplate = "<a class='btn_kvkk'>" + this.translateService.instant("general.kvkk") + "</a>"
  iysTemplate = "<a class='btn_iys'>" + this.translateService.instant("general.iys") + "</a>"

  ngAfterViewInit(): void {
    this.registerDocListeners();
    this.translate.onLangChange.subscribe(res => {
      this.removeDocListeners()
      this.changeDetRef.detectChanges()
      this.registerDocListeners();
    })

  }
  ngOnInit() {
    this.setupForm()
  }

  // ionViewDidEnter() {
  //   this.registerDocListeners()
  // }
  namesurname: FormControl
  phone: FormControl
  email: FormControl
  password: FormControl
  refCode: FormControl
  agreementConfirmation: FormControl
  setupForm() {
    var that = this
    let passwordChecfkSubscription;
    this.namesurname = new FormControl("")
    this.namesurname.addValidators([OrbitFormValidators.phoneNumberValidator(), Validators.required])
    this.phone = new FormControl("")
    this.phone.addValidators([OrbitFormValidators.phoneNumberValidator(), Validators.required])
    this.email = new FormControl("")
    this.email.addValidators([Validators.email])
    this.password = new FormControl("")
    this.password.addValidators([Validators.minLength(6), Validators.maxLength(14), Validators.required])
    this.refCode = new FormControl("")
    this.agreementConfirmation = new FormControl(true)
    this.registerForm = new FormGroup({
      namesurname: this.namesurname,
      phone: this.phone,
      email: this.email,
      password: this.password,
      refCode: this.refCode,
      agreementConfirmation: this.agreementConfirmation
    })

    this.registerForm.controls["email"].valueChanges
      .subscribe(res => {

      })

    this.socialType = this.socialUserInfo ? this.socialUserInfo.Type : null;
    this.socialToken = this.socialUserInfo ? this.socialUserInfo.Id : null;
  }

  registerDocListeners() {
    let options = { initialBreakpoint: 0.98, cssClass: "modal-fullscree modal-height-98 modal-radius modal-agreements", showBackdrop: true }
    document.getElementsByClassName("btn_userAgreement")[0]?.addEventListener("click", (e: any) => {
      console.log(e)
      //TODO tap log event
      let props = {
        "Element_ID": "register_Terms",
        "Timestamp": Date.now(),
        "Screen_Name": "Register",
        "User_ID": "N/A",
        "Session_ID": AnalyticService.SessionId,
        "Event_Type": e.type, //elRef.nativeElement.type,
        "Element_Type": e.srcElement.tagName, //elRef.nativeElement.tagName,
        "Referrer_URL": this.analyticService.previousScreenName, //"n/a", //TODO buraya previousPage datası gelecek
        "Outcome": "open-document",
        "User_Profile": null,
        "Element_Text": e.srcElement.textContent
      }
      this.analyticService.logEvent(AnalyticEvents.TAP, props)
      this.modalService.presentModal({
        component: UserAgreementComponent,
        componentProps: {
          document: environment.privacy,
          title: this.translateService.instant("agreements.user_agreement_title")
        },
        options
      }).then(modalFP => {
        modalFP.modalObj.onDidDismiss().then(dismiss => {
          if (dismiss.data)
            this.registerForm.controls["agreementConfirmation"].setValue(dismiss.data);
        });
      });
    });
    document.getElementsByClassName("btn_kvkk")[0]?.addEventListener("click", (e: any) => {
      let props = {
        "Element_ID": "register_Terms",
        "Timestamp": Date.now(),
        "Screen_Name": "Register",
        "User_ID": "N/A",
        "Session_ID": AnalyticService.SessionId,
        "Event_Type": e.type, //elRef.nativeElement.type,
        "Element_Type": e.srcElement.tagName, //elRef.nativeElement.tagName,
        "Referrer_URL": this.analyticService.previousScreenName, //"n/a", //TODO buraya previousPage datası gelecek
        "Outcome": "open-document",
        "User_Profile": null,
        "Element_Text": e.srcElement.textContent
      }
      this.analyticService.logEvent(AnalyticEvents.TAP, props)
      this.modalService.presentModal({
        component: UserAgreementComponent,
        componentProps: {
          document: environment.kvkk,
          title: this.translateService.instant("agreements.kvkk_title")
        },
        options
      }).then(modalFP => {
        modalFP.modalObj.onDidDismiss().then(dismiss => {
          if (dismiss.data)
            this.registerForm.controls["agreementConfirmation"].setValue(dismiss.data);
        });
      });
    });
    document.getElementsByClassName("btn_iys")[0]?.addEventListener("click", (e: any) => {
      let props = {
        "Element_ID": "register_Terms",
        "Timestamp": Date.now(),
        "Screen_Name": "Register",
        "User_ID": "N/A",
        "Session_ID": AnalyticService.SessionId,
        "Event_Type": e.type, //elRef.nativeElement.type,
        "Element_Type": e.srcElement.tagName, //elRef.nativeElement.tagName,
        "Referrer_URL": this.analyticService.previousScreenName, //"n/a", //TODO buraya previousPage datası gelecek
        "Outcome": "open-document",
        "User_Profile": null,
        "Element_Text": e.srcElement.textContent
      }
      this.analyticService.logEvent(AnalyticEvents.TAP, props)
      this.modalService.presentModal({
        component: UserAgreementComponent,
        componentProps: {
          document: environment.commercialElectronicMessage,
          title: this.translateService.instant("agreements.iys_title")
        },
        options
      }).then(modalFP => {
        modalFP.modalObj.onDidDismiss().then(dismiss => {
          if (dismiss.data)
            this.registerForm.controls["agreementConfirmation"].setValue(dismiss.data);
        });
      });
    });
  }

  removeDocListeners() {
    let btn_userAgreement: any = document.getElementsByClassName("btn_userAgreement")
    if (btn_userAgreement && btn_userAgreement[0] && btn_userAgreement[0]?.removeAllListeners)
      btn_userAgreement[0]?.removeAllListeners("click")
    let btn_kvkk: any = document.getElementsByClassName("btn_kvkk")
    if (btn_kvkk && btn_kvkk[0] && btn_kvkk[0]?.removeAllListeners)
      btn_kvkk[0]?.removeAllListeners("click")
    let btn_iys: any = document.getElementsByClassName("btn_iys")
    if (btn_iys && btn_iys[0] && btn_iys[0]?.removeAllListeners)
      btn_iys[0]?.removeAllListeners("click")
  }

  currentCountryInfo
  dialCode
  lastPhonenumber: string = ""

  phoneInputChanged(ev, type) {
    switch (type) {
      case "countryChanged":
        this.currentCountryInfo = ev;
        this.dialCode = ev.dialCode
        this.storageService.set(StorageKeys.PHONE_CODE, { dialCode: this.dialCode }).then(res => { })
        break;
      case "phoneChanged":
        let phone: string = ev.parsedNumber;
        this.lastPhonenumber = phone
        if (this.currentCountryInfo) {
          this.registerForm.patchValue({ phone: phone }, { emitEvent: true })
          this.isPhoneValid = true
        } else {
          this.isPhoneValid = false
        }
        break;
      case "phoneValidationChanged":
        if (!ev) {
          this.registerForm.patchValue({ phone: null }, { emitEvent: true })
          this.isPhoneValid = false
        } else {
          this.isPhoneValid = true
        }
        break;
      default:
        break;
    }
  }

  submitRegister() {
    if (this.registerForm.valid) {
      var registerControls = this.registerForm.controls
      if (registerControls["agreementConfirmation"].value) {
        let phone = this.registerForm.get("phone").value.split("").map(t => !Number.isNaN(Number.parseInt(t)) ? t : null).filter(t => t).join("")
        let payload = {
          NameSurname: registerControls["namesurname"].value,
          PhoneNumber: this.dialCode + phone,
          Mail: registerControls["email"].value,
          Password: registerControls["password"].value,
          SocialType: this.socialType,
          SocialToken: this.socialToken
        } as IRegisterModelV2;
        this.loading.showLoading();
        this.accountService.Register(payload).then(response => {
          this.onboardingStorageService.completeAppOnboarding()
          this.loading.hideLoading();
          this.farmService.GetAllFarms({ refreshFarms: false, showLoading: false });

          this.onRegister.emit(true)
          this.router.navigate(["/tabs/map"], { replaceUrl: true })
        }).catch(err => {
          this.loading.hideLoading();
          this.toastService.PresentBasicToast({
            message: this.translate.instant("auth." + (err.Message || "error_on_register")),
            type: Message_Type.DANGER
          })
        })
      }

    }
  }
  clearInvitationCode() {
    // this.registerForm.patchValue({ refCode: "" }, { emitEvent: true })
  }

  emailOnBlur(ev) {
    let email = this.registerForm.get("email").value as string
    if (email) {
      email = email.trim();
      this.registerForm.patchValue({ email }, { emitEvent: true, onlySelf: true })
    }
  }
  setAutofillValue(ev, fieldName) {
    let fieldObj = {}
    fieldObj[fieldName] = ev.target.value
    this.registerForm.patchValue(fieldObj, { emitEvent: true, onlySelf: true })
  }

  openFlagsModal() {
    this.loading.showLoading()
    this.intlPhoneService.GetCountryCodes().subscribe(codesRes => {
      this.modalService.presentListModal({
        hasConfirmBtn: false,
        isFullscreen: true,
        isMultiple: false,
        showSearchbar: true,
        searchPlaceholder: this.translateService.instant("general.search_countries"),
        itemList: codesRes.map(t => new ListModalModel({ id: t.iso2, data: t, value: `${t.name} (+${t.dialCode})`, icon: environment.countryFlags + t.iso2.toUpperCase() + '.svg', iconSource: "image", iconStyle: "width:28px;height:20px", iconSlot: "start" })),
        title: this.translateService.instant("general.countries"),
        confirmBtnText: null,
      }).then(resPresenter => {
        this.loading.hideLoading()
        resPresenter.modalObj.onDidDismiss().then(dismiss => {
          if (dismiss.role == "itemSelected") {
            this.selectedCountry = dismiss.data.selectedItem.data
            this.countryCodes = this.selectedCountry.iso2
            this.generateMaskAndPlaceholder()
          }
        })
      })
    })
  }
  phoneMask: MaskitoOptions = {
    mask: ['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
  };

  readonly maskPredicate: MaskitoElementPredicateAsync = async (el: unknown) => (el as ArpaInputComponent).getInputElement();

  selectedCountry
  placeholder
  generateMaskAndPlaceholder() {
    this.intlPhoneService.GetCountryCodes().subscribe({
      next: (ev: Array<any>) => {
        if (!this.selectedCountry)
          this.selectedCountry = ev.find(t => t.iso2 == this.countryCodes.split(",")[0])

        let results = this.intlPhoneService.GeneratePlaceholderAndMask(this.selectedCountry)
        let splittedMask = results.mask.split("").map(t => {
          return Number.parseInt(t) >= 0 ? /\d/ : t
        })
        this.phoneMask = { mask: splittedMask }
        this.dialCode = this.selectedCountry.dialCode
        this.placeholder = results.placeholder
      }
    })
  }
  
  openLangModal() {
    this.langService.openOptionsMenu()
  }

  completeStage(stageName) {
    if (stageName == 'namesurname') {
      this.registerStates = 'phone'
      this.analyticService.currentScreenName = SCREEN_NAME_BY_COMPONENT_NAME.RegisterComponentStep2.screenName
    }
    else if (stageName == "phone") {
      this.registerStates = "password"
      this.analyticService.currentScreenName = SCREEN_NAME_BY_COMPONENT_NAME.RegisterComponentStep3.screenName
    }
    else if (stageName == "password")
      this.submitRegister()
  }

  goPreviousState() {
    if (this.registerStates == 'phone') {
      this.registerStates = 'namesurname'
      this.analyticService.currentScreenName = SCREEN_NAME_BY_COMPONENT_NAME.RegisterComponent.screenName
    }
    else if (this.registerStates == "password") {
      this.registerStates = "phone"
      this.analyticService.currentScreenName = SCREEN_NAME_BY_COMPONENT_NAME.RegisterComponentStep2.screenName
    }
  }
}
