import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MaskitoElementPredicateAsync, MaskitoOptions } from '@maskito/core';
import { TranslateService } from '@ngx-translate/core';
import { OrbitFormValidators } from 'src/app/core/helper/class/form-validators';
import { ArpaInputComponent } from 'src/app/core/helper/components/arpa-input/arpa-input.component';
import { UserAgreementComponent } from 'src/app/core/helper/components/user-agreement/user-agreement.component';
import { BaseModal } from 'src/app/core/models/classes/base-modal';
import { Message_Type } from 'src/app/core/models/enums/message-type';
import { ComponentType } from 'src/app/core/models/enums/phone-input-component-types';
import { StorageKeys } from 'src/app/core/models/enums/storage-keys';
import { IGuestRegisterModel } from 'src/app/core/models/interfaces/guest-models';
import { ListModalModel } from 'src/app/core/models/interfaces/modal-components-models';
import { IRegisterModel } from 'src/app/core/models/interfaces/register-model';
import { AccountService } from 'src/app/core/services/account.service';
import { FarmService } from 'src/app/core/services/farm.service';
import { IntlPhoneInputService } from 'src/app/core/services/intl-phone.service';
import { LanguageService } from 'src/app/core/services/lang.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { environment } from 'src/environments/environment';
import { ForgetPasswordComponent } from '../forget-password/forget-password.component';
import { ILoginModel } from 'src/app/core/models/interfaces/login-model';
import { LocationService } from 'src/app/core/services/location.service';

@Component({
  selector: 'orbit-guest-register-modal',
  templateUrl: './guest-register-modal.component.html',
  styleUrls: ['./guest-register-modal.component.scss', './../../auth.page.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GuestRegisterModalComponent extends BaseModal implements OnInit, AfterViewInit {
  @Input() currentPage: "login" | "register"
  segment
  @Input() idPrefix: string
  registerForm: UntypedFormGroup;
  countryCodes: string = "us"
  componentType = ComponentType.FULL_INPUT_SEPERATED
  passwordVisibility: boolean = false

  phoneMask: MaskitoOptions = {
    mask: ['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
  };

  constructor(
    private modalService: ModalService,
    private storageService: StorageService,
    private translateService: TranslateService,
    private changeDetRef: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private intlPhoneService: IntlPhoneInputService,
    private loading: LoadingService,
    private translate: TranslateService,
    private accountService: AccountService,
    private farmService: FarmService,
    private toastService: ToastService) {
    super()
    super._modalService = modalService
    let rawList = translateService.getBrowserCultureLang().split("-")
    let _countryCodes = "us";
    if (rawList.length > 1) {
      let code = rawList[1].toLowerCase();
      _countryCodes = code + "," + _countryCodes
    } else {
      _countryCodes = rawList[0] + "," + _countryCodes
    }

    if (LanguageService.CountryCodeFromIP && LanguageService.CountryCodeFromIP !== '') {
      this.countryCodes = LanguageService.CountryCodeFromIP + "," + this.countryCodes
      this.generateMaskAndPlaceholder()
    }
    else
      LocationService.GetLocationFromIp().then(res => {
        if (res?.countryCode)
          _countryCodes = res.countryCode.toLowerCase() + "," + _countryCodes
        this.countryCodes = _countryCodes
        this.generateMaskAndPlaceholder()
      }).catch(err => {
        this.countryCodes = _countryCodes
        this.generateMaskAndPlaceholder()
      })
  }
  ngAfterViewInit(): void {
    this.registerDocListeners();
    this.translate.onLangChange.subscribe(res => {
      this.changeDetRef.detectChanges()
      this.registerDocListeners();
    })
  }

  userAgreementTemplate = "<a class='btn_userAgreement'>" + this.translate.instant("general.userAgreement") + "</a>"
  kvkkTemplate = "<a id='btn_kvkk'>" + this.translate.instant("general.kvkk") + "</a>"
  iysTemplate = "<a id='btn_iys'>" + this.translate.instant("general.iys") + "</a>"

  ngOnInit() {
    this.segment = this.currentPage
    super.ngOnInit()
    this.setupForm()
  }

  segmentChanged(ev) {
    this.segment = ev.detail.value
  }

  setupForm() {

    this.loginForm = this.formBuilder.group({
      phone: ["", [OrbitFormValidators.phoneNumberValidator(), Validators.required]],
      password: ["", [Validators.required]],
    });
    this.loginForm.valueChanges.subscribe(res => {

    })
    var that = this
    this.registerForm = this.formBuilder.group({
      namesurname: ["", [OrbitFormValidators.nameSurnameValidator(), Validators.required]],
      phone: ["", [OrbitFormValidators.phoneNumberValidator(), Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [(ev) => {
        if (that.registerForm) {
          let control = that.registerForm.controls["passwordCheck"]
          setTimeout(() => {
            if (control.value && control.value !== '') {
              control.markAsDirty({ onlySelf: true })
              control.enable({ emitEvent: true, onlySelf: true })
            }
          }, 500);
          let response = OrbitFormValidators.mismatchPasswordValidation(control)
          return response(ev)
        } else
          return null
      }, Validators.minLength(6), Validators.maxLength(14), Validators.required]],
      passwordCheck: ['', [(ev) => {
        if (that.registerForm) {
          let control = that.registerForm.controls["password"]
          setTimeout(() => {
            if (control.value && control.value !== '') {
              control.markAsDirty({ onlySelf: true })
              control.enable({ emitEvent: true, onlySelf: true })
            }
          }, 500);
          let response = OrbitFormValidators.mismatchPasswordValidation(control)
          return response(ev)
        }
        else
          return null
      }, Validators.minLength(6), Validators.maxLength(14), Validators.required]],
      refCode: [''],
      agreementConfirmation: [false, [Validators.required]],
    });
  }
  registerDocListeners() {
    let options = { initialBreakpoint: 0.98, cssClass: "modal-fullscreen modal-height-98 modal-radius modal-agreements", showBackdrop: true }
    document.getElementsByClassName("btn_userAgreement")[0]?.addEventListener("click", (e) => {
      this.modalService.presentModal({
        component: UserAgreementComponent,
        componentProps: {
          document: environment.privacy,
          title: this.translate.instant("agreements.user_agreement_title")
        },
        options
      }).then(modalFP => {
        modalFP.modalObj.onDidDismiss().then(dismiss => {
          if (dismiss.data)
            this.registerForm.controls["agreementConfirmation"].setValue(dismiss.data);
        });
      });
    });
    document.getElementsByClassName("btn_kvkk")[0]?.addEventListener("click", (ev) => {
      this.modalService.presentModal({
        component: UserAgreementComponent,
        componentProps: {
          document: environment.kvkk,
          title: this.translate.instant("agreements.kvkk_title")
        },
        options
      }).then(modalFP => {
        modalFP.modalObj.onDidDismiss().then(dismiss => {
          if (dismiss.data)
            this.registerForm.controls["agreementConfirmation"].setValue(dismiss.data);
        });
      });
    });
    document.getElementsByClassName("btn_iys")[0]?.addEventListener("click", (ev) => {
      this.modalService.presentModal({
        component: UserAgreementComponent,
        componentProps: {
          document: environment.commercialElectronicMessage,
          title: this.translate.instant("agreements.iys_title")
        },
        options
      }).then(modalFP => {
        modalFP.modalObj.onDidDismiss().then(dismiss => {
          if (dismiss.data)
            this.registerForm.controls["agreementConfirmation"].setValue(dismiss.data);
        });
      });
    });
  }

  currentCountryInfo
  dialCode
  lastPhonenumber: string = ""

  phoneInputChanged(ev, type) {
    switch (type) {
      case "countryChanged":
        this.currentCountryInfo = ev;
        this.dialCode = ev.dialCode
        this.storageService.set(StorageKeys.PHONE_CODE, { dialCode: this.dialCode }).then(res => { })
        break;
      case "phoneChanged":
        let phone: string = ev.parsedNumber;
        this.lastPhonenumber = phone
        if (this.currentCountryInfo) {
          this.registerForm.patchValue({ phone: phone }, { emitEvent: true })
        } else {

        }
        break;
      case "phoneValidationChanged":
        if (!ev) {
          this.registerForm.patchValue({ phone: null }, { emitEvent: true })
        }
        break;
      default:
        break;
    }
  }

  submitRegister() {
    if (this.registerForm.valid) {
      var registerControls = this.registerForm.controls
      let payload = {
        NameSurname: registerControls["namesurname"].value,
        PhoneNumber: this.dialCode + registerControls["phone"].value,
        Mail: registerControls["email"].value,
        Password: registerControls["password"].value
      } as IGuestRegisterModel
      this.accountService.RegisterGuestUser(payload).then(response => {
        this.farmService.GetAllFarms({ refreshFarms: false, showLoading: false });
        this.toastService.PresentBasicToast({
          message: this.translate.instant("auth.register_succeed")
        }).then(response => {
        })
        this.dismiss({}, "register")
        // this.router.navigate(["/tabs/map"], { replaceUrl: true })
      }).catch(err => {
        this.toastService.PresentBasicToast({
          message: this.translate.instant("auth." + (err.Message || "error_on_register")),
          type: Message_Type.DANGER
        })
      })
    }
  }

  loginListener(ev) {

    this.dismiss({}, "loggedIn")
  }
  clearInvitationCode() {
    this.registerForm.patchValue({ refCode: "" }, { emitEvent: true })
  }

  openFlagsModal() {
    this.loading.showLoading()
    this.intlPhoneService.GetCountryCodes().subscribe(codesRes => {
      this.modalService.presentListModal({
        hasConfirmBtn: false,
        isFullscreen: true,
        isMultiple: false,
        showSearchbar: true,
        searchPlaceholder: this.translateService.instant("general.search_countries"),
        itemList: codesRes.map(t => new ListModalModel({ id: t.iso2, data: t, value: `${t.name} (+${t.dialCode})`, icon: environment.countryFlags + t.iso2.toUpperCase() + '.svg', iconSource: "image", iconStyle: "width:28px;height:20px", iconSlot: "start" })),
        title: this.translateService.instant("general.countries"),
        confirmBtnText: null,
      }).then(resPresenter => {
        this.loading.hideLoading()
        resPresenter.modalObj.onDidDismiss().then(dismiss => {
          if (dismiss.role == "itemSelected") {
            this.selectedCountry = dismiss.data.selectedItem.data
            this.countryCodes = this.selectedCountry.iso2
            this.generateMaskAndPlaceholder()
          }
        })
      })
    })
  }

  readonly maskPredicate: MaskitoElementPredicateAsync = async (el: unknown) => (el as ArpaInputComponent).getInputElement();

  selectedCountry
  placeholder
  generateMaskAndPlaceholder() {
    this.intlPhoneService.GetCountryCodes().subscribe({
      next: (ev: Array<any>) => {
        if (!this.selectedCountry)
          this.selectedCountry = ev.find(t => t.iso2 == this.countryCodes.split(",")[0])

        let results = this.intlPhoneService.GeneratePlaceholderAndMask(this.selectedCountry)
        let splittedMask = results.mask.split("").map(t => {
          return Number.parseInt(t) >= 0 ? /\d/ : t
        })
        this.phoneMask = { mask: splittedMask }
        this.dialCode = this.selectedCountry.dialCode
        this.placeholder = results.placeholder
      }
    })
  }


  //#region login part
  loginForm
  areCrendentialsWrong: boolean = false
  onSubmit() {
    this.areCrendentialsWrong = false
    if (this.loginForm.valid) {
      this.loading.showLoading()
      console.log(this.loginForm.get("phone").value)
      let phone = this.loginForm.get("phone").value.split("").map(t => !Number.isNaN(Number.parseInt(t)) ? t : null).filter(t => t).join("")
      let payload: ILoginModel = {
        email: this.dialCode + phone + "",
        password: this.loginForm.get("password").value
      }
      this.accountService.Login(payload).then(res => {
        // this.purchaseService.registerProducts();
        this.farmService.GetAllFarms({ refreshFarms: true, showLoading: true });

        this.router.navigate(["/tabs/map"], { replaceUrl: true }).then(ev => {
          this.loading.hideLoading();
        })
        // this.toastService.PresentBasicToast({
        //   message: this.langService.instant("auth.login_succeed")
        // }).then(response => {
        // })
      }).catch(err => {
        this.loading.hideLoading();
        this.areCrendentialsWrong = true
        // let errMessage = typeof err === 'string' ? err : (err.message || err.Message)
        // this.toastService.PresentBasicToast({
        //   message: this.translateService.instant(`auth.${errMessage}`)
        // }).then(response => {

        // })
      })
    } else {
      this.areCrendentialsWrong = true
      // this.toastService.PresentBasicToast({
      //   message: this.translateService.instant("auth.login_not_valid")
      // }).then(response => {

      // })
    }
  }

  forgetPassword() {
    let opts = ModalService.defaultOptionsTransparent;
    opts.cssClass = "modal-fullscreen modal-auth"
    this.modalService.presentModal({
      component: ForgetPasswordComponent,
      componentProps: {},
      options: opts
    }).then(modalFP => {
      modalFP.modalObj.onDidDismiss().then(dismiss => {

      })
    })
  }


  setPwd(event) {
    // this.password = event.target.value;
    this.loginForm.patchValue({ password: event.target.value }, { emitEvent: true, onlySelf: true })
  }

  //#endregion
}