import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpRequestTypes } from '../models/enums/http-request-types';
import { ClientBaseApiService } from './client-base-api.service';
import { IVraResponse } from '../models/interfaces/vra-map-response';
import { IFertilizerRate } from '../models/interfaces/fertilizer-rate';
import { IVraLogSaveData, IVraLogSaveModel } from '../models/interfaces/vra-log-save-model.interface';

@Injectable({ providedIn: 'root' })
export class VraMapApiService {
    constructor(private baseApiClient: ClientBaseApiService) { }

    public GetVraListRequest(props: { id?: number, tempFarmAttId?: number }) {
        let url = environment.gatewayApiUrl + "v2/api/vra/list";
        if (props && Object.keys(props).length > 0) {
            let queryListArray = []
            Object.keys(props).forEach(el => {
                queryListArray.push(`${el}=${props[el]}`)
            })
            if (queryListArray.length > 0)
                url = url + "?" + queryListArray.join("&")
        }
        return this.baseApiClient.baseApiCall<Array<IVraResponse>>({ url, payload: null, requestType: HttpRequestTypes.GET })
    }

    public SaveVraRecord(payload: IVRASaveModel) {
        let url = environment.gatewayApiUrl + "v2/api/vra/save"
        return this.baseApiClient.baseApiCall<number>({ url, payload, requestType: HttpRequestTypes.POST })
    }
    public getFertilizerRate(vraId, percentage) {
        let url = `${environment.gatewayApiUrl}v2/api/vra/fertilizer/rate?vraId=${vraId}&percentage=${percentage}`;
        return this.baseApiClient.baseApiCall<IFertilizerRate>({ url, payload: null, requestType: HttpRequestTypes.GET })
    }

    public setLastProdZoneAndDoseDiff(vraid, doseDiff, zone) {
        let url = environment.gatewayApiUrl + "v2/api/vra/save/last/choose";
        let payload = {
            "VraId": vraid,
            "Percentage": doseDiff,
            "Zone": zone
        }
        return this.baseApiClient.baseApiCall<boolean>({ url, payload, requestType: HttpRequestTypes.POST })
    }

    public saveVraMapLog(payload: IVraLogSaveModel){
        let url = environment.gatewayApiUrl + "v2/api/vra/save/vramap/log";
        return this.baseApiClient.baseApiCall<number>({url, payload, requestType: HttpRequestTypes.POST})
    }

    public getVraMapLog(vraId){
        let url = environment.gatewayApiUrl + "v2/api/vra/vramap/log/" + vraId;
        return this.baseApiClient.baseApiCall<IVraLogSaveData[]>({url, payload:null, requestType: HttpRequestTypes.GET})
    }
}


export interface IVRASaveModel {
    Id?: number // tarla id
    VraTypeId: number
    ProductName: string
    SensingDates: string[]
    StrategyId: number
    PlannedAverageDose: number
    TempFarmAttId: number
    UnitId: number
    FertilizerTypeId: number
    LeftCalibrationValue?
    RightCalibrationValue?
}