import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { StorageService } from "../services/storage.service";
import { AppOfflineService } from "../services/app-offline.service";

@Injectable({
    providedIn: "root"
})
export class OfflineGuard  {

    /**
     *
     */
    constructor(private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        return new Observable<boolean | UrlTree>(
            observe => {
                if(AppOfflineService.isOnline)
                    observe.next(true)
                else{
                    observe.next(false)
                    this.router.navigate(["offline-home"],{replaceUrl: true});
                }
            }
        )
    }

}