import { Injectable } from '@angular/core';
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import { Capacitor } from '@capacitor/core';
import { AccountService } from './account.service';
import { StorageService } from './storage.service';
import { StorageKeys } from '../models/enums/storage-keys';
import { environment } from 'src/environments/environment';

const firebaseAppIds = {
    android: "1:695797451030:android:ae8f5aed0303a758",
    ios: "1:695797451030:ios:ae8f5aed0303a758"
}
const apiSecret = ""

@Injectable({ providedIn: 'root' })
export class GoogleFirebaseAnalyticsService {

    private _appInstanceId: string = null
    private _currentUserId: string = null
    private _platform: string = Capacitor.getPlatform().toLowerCase()
    public sessionId: string = null
    constructor(private storageService: StorageService) {
        // Capacitor.isNativePlatform = () => true
    }

    public enableFirebaseAnalytics() {
        if (Capacitor.isNativePlatform())
            return FirebaseAnalytics.enable()//.then(res => { }).catch(err => { })
        else
            return Promise.resolve()
    }

    public disableFirebaseAnalytics() {
        if (Capacitor.isNativePlatform())
            return FirebaseAnalytics.disable()//.then(res => { }).catch(err => { })
        else
            return Promise.resolve()
    }

    public getAppInstanceId() {

        if (Capacitor.isNativePlatform())
            return new Promise((resolve, reject) => {
                if (this._appInstanceId)
                    resolve(this._appInstanceId)
                else
                    FirebaseAnalytics.getAppInstanceId().then(res => {
                        this._appInstanceId = res.instanceId
                        resolve(this._appInstanceId)
                    }).catch(err => {
                        reject(err)
                    })
            })
        else
            return Promise.resolve()
    }

    public setUserId(userId): Promise<void> {

        if (Capacitor.isNativePlatform())
            return new Promise((resolve, reject) => {
                FirebaseAnalytics.setUserId({ userId }).then(res => {
                    this._currentUserId = userId
                    resolve()
                }).catch(err => {
                    reject(err)
                })
            })
        else
            return Promise.resolve()
    }

    public setUserParameters(props) {

        if (Capacitor.isNativePlatform()) {
            //burada parametre olarak alınan objeyi tek tek göndermek gerekecek.
            Object.keys(props).forEach(el => {
                FirebaseAnalytics.setUserProperty({ name: el, value: props[el] }).then().catch()
            })
        } else
            return Promise.resolve()
    }

    public async sendEvetLog(eventName: string, options: { [key: string]: any }): Promise<any> {
        if (Capacitor.isNativePlatform()) {
            if (!this._appInstanceId)
                await this.getAppInstanceId()
            if (!Array.isArray(Object.keys(options)))
                options = {}
            if (this._currentUserId && !options.User_ID)
                options.User_ID = this._currentUserId;
            options.timestampMicros = Date.now()
            if (!this.sessionId || this.sessionId == "")
                this.sessionId = Date.now().toString()
            options.sessionId = this.sessionId
            options.appInstanceId = this._appInstanceId
            if (!environment.production)
                options["traffic_type"] = "internal"
            FirebaseAnalytics.logEvent({
                name: eventName,
                params: options
            }).then(r => { }).catch(err => {})
            return Promise.resolve()
            // options.loginStatus = AccountService.IsGuest
            // if (this.sessionId) {
            //     options.sessionId = this.sessionId
            //     return FirebaseAnalytics.logEvent({
            //         name: eventName,
            //         params: options
            //     })
            // }else{
            //     return new Promise((resolve, reject) => {
            //         this.storageService.get(StorageKeys.SESSION_INFOS).then(sessionResp => {
            //             if(sessionResp && sessionResp.IsSuccess && sessionResp.Data){
            //                 this.sessionId = sessionResp.Data.sessionId
            //                 options.sessionId = this.sessionId
            //                 FirebaseAnalytics.logEvent({
            //                     name: eventName,
            //                     params: options
            //                 })
            //             }
            //         })
            //     })
            // }
        } else
            return Promise.resolve()
    }

    public setScreenName(screen) {
        if (Capacitor.isNativePlatform())
            return FirebaseAnalytics.setScreenName({
                screenName: screen,
                nameOverride: screen,
            })
        else
            return Promise.resolve()
    }
}