export class AppOfflineDetails {
    StartDate: Date
    SelectedOfflinePages: string[]
    IsOffline: boolean

    /**
     *
     */
    constructor(startDate: Date | string | number = null, selectedOfflinePages: string[] = [], isOffline: boolean = false) {
        this.StartDate = startDate = null
        this.SelectedOfflinePages = selectedOfflinePages
        this.IsOffline = isOffline
    }

}


export interface IOfflinePageListModel {
    Id: number
    FarmName: string
    TempFarmAttId: number
    Date: Date | string | number
    Details: string
}