import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { defineCustomElements } from '@ionic/pwa-elements/loader';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .then(res => {
    // console.log = (args) => { }
    // console.info = (args) => { }
    
    // console.warn = (args) => { }
    // Call the element loader after the platform has been bootstrapped
    defineCustomElements(window);
  })
  .catch(err => { });