import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpRequestTypes } from '../models/enums/http-request-types';
import { IApiCallBase } from '../models/interfaces/api-call-base';
import { IExternalApiCallBase } from '../models/interfaces/external-api-call';
import { IGenericResponse } from '../models/interfaces/i-generic-response';
import { shareReplay } from "rxjs/operators";
import { StorageService } from '../services/storage.service';
import { StorageKeys } from '../models/enums/storage-keys';
import { AccountService } from '../services/account.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { FirebaseCrashService } from '../services/firebase-crash.service';
import { AppInsightsLoggingService } from '../services/logging/logging.service';
import { AppOfflineService } from '../services/app-offline.service';
import { UtilsService } from '../services/utils.service';

@Injectable({
  providedIn: 'root'
})
export class ClientBaseApiService {

  private _externalHttpClient: HttpClient
  constructor(private httpClient: HttpClient, private storageService: StorageService, private router: Router, private handler: HttpBackend, private firebaseCrashService: FirebaseCrashService) {
    this._externalHttpClient = new HttpClient(handler);
  }

  /**
   * @description return without authorization check. even if return 401, user can continue to use app
   */
  public baseApiCall<T = any>(props: IApiCallBase): Observable<IGenericResponse<T>> {

    if (StorageService.isOnline) {
      let request: Observable<IGenericResponse<T>> = null
      switch (props.requestType) {
        case HttpRequestTypes.GET:
          request = this.httpClient.get<IGenericResponse<T>>(props.url)
          break;
        case HttpRequestTypes.POST:
        default:
          request = this.httpClient.post<IGenericResponse<T>>(props.url, props.payload)
          break;
      }
      return new Observable<IGenericResponse<T>>(observer => {
        request.subscribe({
          next: response => {
            if (response.IsSuccess) {
              observer.next(response)
            } else {
              observer.error(response)
            }
            observer.complete();
          },
          error: error => {
            this._logError(props, error)
            let errorObj = { IsSuccess: false, Data: error, Message: "http_request_unexpected_error", StatusCode: -1 } as IGenericResponse<any>
            observer.error(errorObj)
            observer.complete();
          },
          complete: () => {
            // observer.complete();
          }
        })
      })
    } else {
      return UtilsService.EmptyObservableRequest<IGenericResponse<T>>(true, 100)
    }
  }

  /**
   * 
   * @param props 
   * @returns Observable<IGenericResponse<T>>
   * @description returns a subcription with authorization check. If request returns 401, app redrects to login page
   */
  public strictedApiCall<T = any>(props: IApiCallBase): Observable<IGenericResponse<T>> {
    if (StorageService.isOnline) {
      let request: Observable<IGenericResponse<T>> = null
      switch (props.requestType) {
        case HttpRequestTypes.GET:
          request = this.httpClient.get<IGenericResponse<T>>(props.url)
          break;
        case HttpRequestTypes.POST:
        default:
          request = this.httpClient.post<IGenericResponse<T>>(props.url, props.payload)
          break;
      }
      return new Observable<IGenericResponse<T>>(observer => {
        request.subscribe(
          {
            next: response => {
              if (response.IsSuccess) {
                observer.next(response)
              } else {
                observer.error(response)
              }
              observer.complete();
            },
            error: error => {
              if (error.status === 401) {
                StorageService.Remove(StorageKeys.TOKEN).then()
                StorageService.Remove(StorageKeys.USER).then()
                AccountService.token = null;
                this.router.navigate(["auth"], { replaceUrl: true })
                this._logError(props, error)
                observer.error({})
                observer.complete();
              } else {
                this._logError(props, error)
                let errorObj = { IsSuccess: false, Data: error, Message: "http_request_unexpected_error", StatusCode: -1 } as IGenericResponse<any>
                observer.error(errorObj)
                observer.complete();
              }
            },
            complete: () => {
              // observer.complete();
            }
          })
      })
    } else {
      return UtilsService.EmptyObservableRequest<IGenericResponse<T>>(true, 100)
    }
  }



  public externalApiCall<T>(props: IExternalApiCallBase): Observable<T> {
    if (StorageService.isOnline) {
      var headers: HttpHeaders = new HttpHeaders();
      if (props.responseType)
        headers.append("Response-Type", props.responseType)
      switch (props.requestType) {
        case HttpRequestTypes.POST:
          return this._externalHttpClient.post<T>(props.url, props.payload, { headers });
          break;
        case HttpRequestTypes.GET:
          return this._externalHttpClient.get<T>(props.url, { headers: headers });
          break;
        default:
          const error: IGenericResponse<any> = {
            Data: null,
            IsSuccess: false,
            Message: "no_request_type",
            StatusCode: "-1"
          }
          return new Observable<any>(observer => {
            observer.error(error);
            observer.complete()
          })
          break;
      }
    }else {
      return UtilsService.EmptyObservableRequest<T>(true, 100)
    }
  }


  private _logError(props, error) {
    try {
      let _payload = { ...props.payload }
      if (!_payload || _payload == '')
        _payload = {}
      this.firebaseCrashService.RecordException(error.message, error.code, error.url, JSON.stringify(_payload))
      // AppInsightsLoggingService.logException({
      //   message: error.message,
      //   name: "http_request_error",
      //   stack: ""
      // }, null, {
      //   data: error,
      //   payload: _payload
      // })
    } catch (err) { }
    // AppInsightsLoggingService.logException({
    //   message: error.message,
    //   name: "http_request_error",
    //   stack: ""
    // }, null, {
    //   data: error,
    //   payload: { ...props.payload }
    // })
  }
}
