import { GoogleFirebaseAnalyticsService } from './core/services/google-firebase-analytics.service';
import { DateTimeService } from './core/services/date-time.service';
import { Component, NgZone, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { StorageKeys } from './core/models/enums/storage-keys';
import { AnalyticService } from './core/services/analytic.service';
import { LanguageService } from './core/services/lang.service';
import { LoadingService } from './core/services/loading.service';
import { ModalService } from './core/services/modal.service';
import { NavigationService } from './core/services/navigation.service';
import { NetworkService } from './core/services/network.service';
import { AppConstantsService } from './core/services/app-constants.service';
import { StorageService } from './core/services/storage.service';
import { UtilsService } from './core/services/utils.service';
import { VisualThemeModeService } from './core/services/visual-theme-mode.service';
import { ClientStatusBarService } from './core/services/client-status-bar.service';
import { IAuthResponse } from './core/models/interfaces/auth-response';
import { UnitPreferenceService } from './core/services/unit-preference.service';
import { AccountService } from './core/services/account.service';
import { PurchasePlansService } from './core/services/purchase-plans.service';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { LocationService } from './core/services/location.service';
import { SplashScreen } from "@capacitor/splash-screen";
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Device } from "@capacitor/device"
import {
  AppTrackingTransparency,
  AppTrackingStatusResponse,
} from 'capacitor-plugin-app-tracking-transparency';
import { Capacitor } from '@capacitor/core';
import { NotificationsService } from './core/services/notification.service';
import { ToastService } from './core/services/toast.service';
import { Message_Type } from './core/models/enums/message-type';
import { IPushNotificationResponse } from './core/models/interfaces/notification-settings-model';
import { Facebook } from "@awesome-cordova-plugins/facebook/ngx";
import { WeatherForecastService } from './core/services/weather-forecast.service';
import { FirebaseCrashService } from './core/services/firebase-crash.service';
import { DoktarAppsFlyer } from './core/services/appsflyer.service';
import { TranslateService } from '@ngx-translate/core';
import { CleverTapService } from './core/services/clevertap.service';
import { FarmService } from './core/services/farm.service';
import { ForceUpdateService } from './core/services/force-update.service';
import { AnalyticEvents } from './core/models/enums/analytic-events';
import { AppInsightsLoggingService } from './core/services/logging/logging.service';
import { AppOfflineDetails } from './core/models/classes/app-offline-details';
import { AppOfflineService } from './core/services/app-offline.service';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  _env = environment
  private _showSplashScreen: boolean = true;
  public get showSplashScreen(): boolean {
    return this._showSplashScreen;
  }
  public set showSplashScreen(value: boolean) {
    this._showSplashScreen = value;
    let fn = () => {
      let doc = document.getElementById("splashScreen")
      if (doc) {
        doc.style.display = value ? 'block' : 'none'
      } else {
        setTimeout(() => {
          fn()
        }, 300);
      }
    }
    fn()
  }
  constructor(
    private locationService: LocationService,
    private navigationService: NavigationService,
    public loadingService: LoadingService,
    private platform: Platform,
    private langService: LanguageService,
    private storageService: StorageService,
    private accountService: AccountService,
    private networkService: NetworkService,
    private analyticService: AnalyticService,
    private unitService: UnitPreferenceService,
    private purchaseService: PurchasePlansService,
    private notificationsService: NotificationsService,
    private toastService: ToastService,
    private router: Router,
    private fb: Facebook,
    private weatherService: WeatherForecastService,
    private firebaseCrashService: FirebaseCrashService,
    private translateService: TranslateService,
    private farmService: FarmService,
    private zone: NgZone,
    private googleFirebaseService: GoogleFirebaseAnalyticsService,
    private forceUpdateService: ForceUpdateService,
    private clevertapService: CleverTapService,
    private appInsightsLoggingService: AppInsightsLoggingService,
    private appOfflineService: AppOfflineService,
    private appConstants: AppConstantsService) {
    // if (Capacitor.getPlatform() === 'ios')
    //   this.showSplashScreen = false
    // this._appLinkOpenListener()
    //location servisin gerekli injectionları çalıştırması için en az 1 kere instance alınması gerekiyor.
    this.locationService = new LocationService()
    this.initDeeplinking()
  }


  initDeeplinking() {
    const PAGE_REDIRECTIONS = {
      account: () => { this.router.navigateByUrl("/tabs/account").then() },
      compare: () => { this.router.navigateByUrl("/compare").then() },
      explore: () => { this.router.navigateByUrl("/tabs/explore").then() },
      myfields: () => { this.router.navigateByUrl("/tabs/my-fields").then() },
      myfieldsnotes: () => { this.router.navigateByUrl("/tabs/my-fields?subpage=notes").then() },
      mypurchases: () => { this.router.navigateByUrl("/tabs/account/my-plans").then() },
      weathers: () => { this.router.navigateByUrl("/weather").then() },
      rainviewer: () => { this.router.navigateByUrl("/weather?subpage=rainviewer").then() },
      //http://localhost:8100/tabs/account/engineer-support
      expertsupport: () => { this.router.navigateByUrl("/engineer-support").then() },
      //http://localhost:8100/tabs/account/engineer-support/detail
      askexpert: () => { this.router.navigateByUrl("/engineer-support/detail").then() },
      drawfield: () => { this.router.navigateByUrl("/add-field?subpage=draw").then() },
      parcelinquiry: () => { this.router.navigateByUrl("/add-field?subpage=parcel").then() },
      importfield: () => { this.router.navigateByUrl("/add-field?subpage=import").then() },
      faq: () => { this.router.navigateByUrl("/tabs/explore/faquestions").then() },
      guides: () => { this.router.navigateByUrl("/tabs/explore/my-guide").then() },
      newFeatures: () => { this.router.navigateByUrl("/tabs/explore/new-features").then() },
      planspricing: () => { this.purchaseService.openPurchasePlans().then() }
    }

    App.addListener("appUrlOpen", (ev: URLOpenListenerEvent) => {
      this.zone.run(() => {
        console.log("appUrlOpen => ", ev)
        if (ev.url.includes("pagekey")) {
          let splittedUrl = ev.url.split("&")
          let rawPage = splittedUrl.find(t => t.includes("pagekey"))
          if (rawPage) {
            let pageName = rawPage.split("pagekey=")[1]
            console.log(pageName)
            console.log(Object.keys(PAGE_REDIRECTIONS).some(t => t == pageName))
            if (Object.keys(PAGE_REDIRECTIONS).some(t => t == pageName))
              setTimeout(() => {
                PAGE_REDIRECTIONS[pageName]()
              }, 1000);
          }
        } else if (ev.url.includes("deep_link_value")) {
          let splittedUrl = ev.url.split("&")
          let rawPage = splittedUrl.find(t => t.includes("deep_link_value"))
          if (rawPage) {
            let pageName = rawPage.split("deep_link_value=")[1]
            console.log(pageName)
            if (Object.keys(PAGE_REDIRECTIONS).some(t => t == pageName))
              setTimeout(() => {
                PAGE_REDIRECTIONS[pageName]()
              }, 1000);
          }
        }
        else {
          let selectedKey = ""
          Object.keys(PAGE_REDIRECTIONS).forEach(el => {
            if (ev.url.includes(el))
              selectedKey = el
          })
          if (selectedKey && PAGE_REDIRECTIONS[selectedKey]) {
            setTimeout(() => {
              PAGE_REDIRECTIONS[selectedKey]()
            }, 1000);
          } else
            if (ev.url.startsWith("com.doktar.tarlam365://")) {
              let pageName = ev.url.split("com.doktar.tarlam365://")[1]
              if (Object.keys(PAGE_REDIRECTIONS).some(t => t == pageName))
                setTimeout(() => {
                  PAGE_REDIRECTIONS[pageName]()
                }, 1000);
            }
        }

      })
    })
  }


  ngOnInit(): void {
    this.initApp()
    // setTimeout(() => {

    SplashScreen.hide()
    // }, 2000);
  }
  public async getStatus(): Promise<AppTrackingStatusResponse> {
    const response = await AppTrackingTransparency.getStatus();

    // { status: 'authorized' } for example

    return response;
  }
  public async requestPermission(): Promise<AppTrackingStatusResponse> {
    const response = await AppTrackingTransparency.requestPermission();

    // { status: 'authorized' } for example

    return response;
  }
  initApp() {
    if (Capacitor.isNativePlatform()) {
      let clientStatusBar = new ClientStatusBarService()
      clientStatusBar.statusBarOverlay()
    }
    let themeService = new VisualThemeModeService(this.storageService, new ClientStatusBarService)
    themeService.initApplicationTheme();
    // KeyboardActionsService.DisableResize();
    // KeyboardActionsService.EnableScroll();
    this.platform.ready().then(async response => {
      this.purchaseService.initStoreObject()
      this.googleFirebaseService.sessionId = Date.now().toString()
      let that = this
      // App.addListener("appStateChange", (ev) => {
      //   if (ev.isActive) {
      //     Device.getId().then(value => {
      //       AnalyticService.SessionId = Date.now() + ""
      //       that.clevertapService.recordEvent(AnalyticEvents.SESSION_START, { Timestamp: AnalyticService.SessionId, Platform: Capacitor.getPlatform(), DeviceId: value.uuid })
      //     })
      //   } else {
      //     that.googleFirebaseService.sendEvetLog("app_killed", {}).then()
      //     Device.getId().then(value => {
      //       console.log("session endded", { Timestamp: AnalyticService.SessionId, Platform: Capacitor.getPlatform(), DeviceId: value.uuid })
      //       that.clevertapService.recordEvent(AnalyticEvents.SESSION_END, { Timestamp: AnalyticService.SessionId, Platform: Capacitor.getPlatform(), DeviceId: value.uuid })
      //     })
      //   }
      //   console.log("appStateChange => ", ev.isActive)
      // })

      // document.addEventListener("destory", function destoryCallback() {
      //   console.log("app_killed")

      // }, false);
      // SplashScreen.hide()
      try {
        const appOfflineStatus = await this.appOfflineService.initOfflineStatus();
      } catch (error) {
        const appOfflineStatus = null
      }
      themeService.setTextZoom();
      if (AppOfflineService.isOnline) {
        this.forceUpdateService.checkForNewVersion()
        if (Capacitor.isNativePlatform()) {
          this.googleFirebaseService.enableFirebaseAnalytics().then().catch()
          // this.analyticService.enableFirebaseAnalytics();
          this.firebaseCrashService.EnableAutoTrack().then();
        }
        this.navigationService.trackNavigation();
        this.navigationService.overrideBackButton();

        let listener = NetworkService.networkListener.subscribe(r => {
          if (r == 'online') {
            listener.unsubscribe()
            listener = undefined;
          } else {

          }
        })
        this.networkService.check()
        this.networkService.initiateNetworkListener()
        //#region push notifications area
        this.notificationsService.checkForPermission().subscribe();
        this.notificationsService.AddPushNotificationRegistrationListeners();
        this.notificationsService.NotificationReceivedListener(e => {
          if (e.data.wzrk_acct_id) {
            if (e.data.nm && e.data.nm !== '')
              this.toastService.PresentToastWithCallback({
                message: e.data.nm,
                duration: 3000,
                type: Message_Type.WARNING,
                buttonRole: "cancel",
                buttonIcon: "close",
                iconSide: "end"
              })
          } else {
            this.toastService.PresentToastWithCallback({
              message: e.data.NotificationText,
              duration: 3000,
              type: Message_Type.WARNING,
              buttonRole: "cancel",
              buttonIcon: "close",
              iconSide: "end"
            })
          }
        })
        this.notificationsService.NotificationOnPerformedListener(e => {
          let notResult = e as IPushNotificationResponse;
          if (notResult.actionId === 'tap') {
            let payload = notResult.notification.data;
            if (payload) {
              this.router.navigateByUrl(payload.RedirectUri)
            }
          }
        })

        //#endregion
        this.analyticService.trackNavigationEnd()
      }
      let utils = new UtilsService(this.platform)
      this.unitService.initializeUnitValues()



      this.setFirstDatas()
      DateTimeService.LocaleCode = this.translateService.instant("lang.culture_info")

      this.langService.getLangOpts().subscribe(r => {
        this.storageService.get<string>(StorageKeys.LANG).then(langResponse => {
          if (langResponse && langResponse.IsSuccess && langResponse.Data !== '') {
            this.langService.setCurrentLang(langResponse.Data);
          } else {
            this.langService.setCurrentLang(this.langService.getBrowserLang());
          }
        })
      })

      if (Capacitor.isNativePlatform()) {
        this.fb.activateApp().then(e => { });
        this.fb.setAdvertiserIDCollectionEnabled(true).then(response => {
          // console.log('setAdvertiserIDCollectionEnabled success');
        }).catch(err => {
          // console.error('setAdvertiserIDCollectionEnabled failure');
        });
      }
      if (Capacitor.getPlatform() == 'ios') {
        this.getStatus();
        this.requestPermission();
        this.fb.setAdvertiserTrackingEnabled(true).then(response => {
        }).catch(err => {
          // console.error('setAdvertiserTrackingEnabled failure');
        })
      }
      this.appConstants.GetAppConstants(true).subscribe(res => {
        if (res) {
          if (AppOfflineService.isOnline)
            setTimeout(() => {
              res.registerOnDeepLink = true
              DoktarAppsFlyer.initAppFlyer(DoktarAppsFlyer.build(res.appsFlyerOptions as any)).subscribe(appsflyerRes => {
              })

            }, 1000);
        }
      })

      this.translateService.onLangChange.asObservable().subscribe(response => {
        DateTimeService.LocaleCode = this.translateService.instant("lang.culture_info")
      })
    })
  }

  setFirstDatas() {
    this.storageService.get<IAuthResponse>(StorageKeys.USER).then(resUser => {
      if (resUser.IsSuccess && resUser.Data) {
        // this.router.navigate(["/tabs/map"])
        this.analyticService.sendState('app.ready').subscribe();

        StorageService.UserInfo = resUser.Data
        AccountService.bearer = resUser.Data.AuthToken
        AccountService.token = resUser.Data.Token
        this._appLaunchLog()
        this.accountService.StartPermissionRequests()
        this.farmService.CheckIfAnyFarmDeletedByOwner()
        this.unitService.GetAccountUnits()
        // this.purchaseService.registerProducts();
        this.accountService.GetCurrentUserPermission()
        this.notificationsService.GetNotificationPermissions().subscribe()
        this.weatherService.GetWeatherByPointFarm().subscribe()
        try {
          try {
            this.googleFirebaseService.setUserId(StorageService.UserInfo.UserRoleId.toString()).then().catch()
          } catch (error) {

          }

          AccountService.instance.setUserProfileData(false)
          AccountService.IsGuest = resUser.Data.IsGuest
          AccountService.NameSurname = resUser.Data.NameSurname
          this.storageService.get(StorageKeys.TOKEN).then(res => {
            if (res.IsSuccess && res.Data) {
              this.accountService.StartPermissionRequests()
              this.farmService.CheckIfAnyFarmDeletedByOwner()
              AccountService.token = res.Data
              this.unitService.GetAccountUnits()
              this.purchaseService.registerProducts();
              this.accountService.GetCurrentUserPermission()
              this.notificationsService.GetNotificationPermissions().subscribe()
              this.weatherService.GetWeatherByPointFarm().subscribe()
            }
            setTimeout(() => {
              this.showSplashScreen = false
            }, 3000);
            // SplashScreen.hide({
            //   fadeOutDuration: 3000
            // }).then()
          }, err => {
            setTimeout(() => {
              this.showSplashScreen = false
            }, 3000);
            // SplashScreen.hide({
            //   fadeOutDuration: 3000
            // }).then()
          })

        } catch (error) {
          setTimeout(() => {
            this.showSplashScreen = false
          }, 3000);

        }
      } else {
        setTimeout(() => {
          this.showSplashScreen = false
        }, 3000);
        this._appLaunchLog()
      }
    }, err => {

      this._appLaunchLog()
      setTimeout(() => {
        this.showSplashScreen = false
      }, 3000);
    })
  }

  private _appLaunchLog() {
    if (Capacitor.isNativePlatform())
      Promise.all([Device.getInfo(), App.getInfo()]).then(response => {
        UtilsService.DeviceDetailsInfo = [response[0].platform, response[0].model, response[0].manufacturer, response[0].operatingSystem, response[0].osVersion].join(", ")
        if (StorageService.UserInfo)
          this.analyticService.logEvent(AnalyticEvents.APP_LAUNCH, {
            "User_ID": StorageService.UserInfo.UserRoleId,
            "Session_ID": AnalyticService.SessionId,
            "Timestamp": Date.now(),
            "App_Version": response[1].version,
            Device: UtilsService.DeviceDetailsInfo
          })
      })
    else {
      UtilsService.DeviceDetailsInfo = ["web", "unknown-web-device", "unknown-web-device-manufacture", "unknown-web-device-os", "unknown-web-device-os-version"].join(", ")
      if (StorageService.UserInfo)
        this.analyticService.logEvent(AnalyticEvents.APP_LAUNCH, {
          "User_ID": StorageService.UserInfo.UserRoleId,
          "Session_ID": AnalyticService.SessionId,
          "Timestamp": Date.now(),
          "App_Version": "web - " + environment.version,
          Device: UtilsService.DeviceDetailsInfo
        })
    }
  }

}
