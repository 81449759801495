export const SCREEN_NAME_BY_COMPONENT_NAME = {
    MapPage: {
        screenName: "Map"
    },
    // ExplorePage: {
    //     screenName: "ExplorePage"
    // },
    AddFieldPage: {
        screenName: "Adding Field Method"
    },
    AddFieldPageStepDrawing: {
        screenName: "Draw a field"
    },
    AddFieldPageStepDrawingSearch: {
        screenName: "Draw a field-search"
    },
    AddFieldPageStepDrawingStep2: {
        screenName: "Add Field Drawing-2"
    },
    AddFieldPageStepDrawingStep3: {
        screenName: "Add Field Drawing-3"
    },
    AddFieldPageStepDrawingStep4: {
        screenName: "Add Field Drawing-4"
    },
    AddFieldPageStepDrawingStep5: {
        screenName: "Add Field Drawing-5"
    },
    LoginComponent: {
        screenName: "Sign-in"
    },
    RegisterComponent: {
        screenName: "RegisterName"
    },
    RegisterComponentStep2: {
        screenName: "RegisterNumber"
    },
    RegisterComponentStep3: {
        screenName: "RegisterPassword"
    },
    // FieldComparisonPage: {
    //     screenName: "FieldComparisonPage"
    // },
    IrrigationProgramsListPage: {
        screenName: "Irrigation"
    },
    AddIrrigationProgramPage: {
        screenName: "Irrigation-Add"
    },
    IrrigationProgramPage: {
        screenName: "Irrigation-Program"
    },
    IrrigationProgramPlantWaterStressPage: {
        screenName: "Irrigation-PLantWaterStress"
    },
    // ReferFriendPage: {
    //     screenName: "ReferFriendPage"
    // },
    // ReferDetailsComponent: {
    //     screenName: "ReferDetailsComponent"
    // },
    SprayingHoursPage: {
        screenName: "Spraying hours"
    },
    SprayingDetailsComponent: {
        screenName: "Spraying hours-detail"
    },
    AddSprayingComponent: {
        screenName: "Spraying hours-Add date"
    },
    NewVraMapComponent: {
        screenName: "New VRA Map"
    },
    NewVraMapComponentDetail: {
        screenName: "VRA-details"
    },
    NewVraMapComponentAddDate: {
        screenName: "VRA-add a date"
    },
    NewVraMapComponentAddDateImage: {
        screenName: "VRA-add a date-image"
    },
    VraMapsPage: { //TODO bu vra loglarının liste sayfası eklenince story olacak. Cana Sor bunu
        screenName: "VRA-Maps"
    },
    VraMapsEmptyPage: {
        screenName: "VRA-empty"
    },
    VraDetailsComponent: {
        screenName: "VRA Story"
    },
    VraSpreaderConfigModal: {
        screenName: "Vra-Irtem"
    },
    IrtemApplicationComponent: {
        screenName: "Irtem Live Screen"
    },
    WeatherForecastPage: {
        screenName: "Weather"
    },
    WeatherRainviewerPage: {
        screenName: "Weather-Rainviewer"
    },
    WeatherDetailComponent: {
        screenName: "Weather-Details"
    },
    // AccountSettingsPage: {
    //     screenName: "AccountSettingsPage"
    // },
    // QuestionDetailsComponent: {
    //     screenName: "QuestionDetailsComponent"
    // },
    // EngineerSupportPage: {
    //     screenName: "EngineerSupportPage"
    // },
    // MyPlansPage: {
    //     screenName: "MyPlansPage"
    // },
    // NotificationsComponent: {
    //     screenName: "NotificationsComponent"
    // },
    // ProfileSettingsComponent: {
    //     screenName: "ProfileSettingsComponent"
    // },
    // AccountPage: {
    //     screenName: "AccountPage"
    // },
    // NotificationSettingsComponent: {
    //     screenName: "NotificationSettingsComponent"
    // },
    // NotificationSettingsDetailComponent: {
    //     screenName: "NotificationSettingsDetailComponent"
    // },
    // ComparePage: {
    //     screenName: "ComparePage"
    // },
    // CompareFarmsComponent: {
    //     screenName: "CompareFarmsComponent"
    // },
    // ComparisonResultComponent: {
    //     screenName: "ComparisonResultComponent"
    // },
    // FaQuestionsComponent: {
    //     screenName: "FaQuestionsComponent"
    // },
    // MyGuideComponent: {
    //     screenName: "MyGuideComponent"
    // },
    // NewFeaturesComponent: {
    //     screenName: "NewFeaturesComponent"
    // },
    FarmDetailComponent: {
        screenName: "Field Detail"
    },
    // NoteDetailModalComponent: {
    //     screenName: "NoteDetailModalComponent"
    // },
    MyFieldsPage: {
        screenName: "Fields"
    },
    MyFieldsNotesPage: {
        screenName: "Notes"
    },
    ToolsPage: {
        screenName: "Tools"
    },
    ForgetPasswordComponent: {
        screenName: "Forget password"
    },
    ForgetPasswordComponentSmsValidation: {
        screenName: "Enter Code"
    },
    ForgetPasswordComponentNewPassword: {
        screenName: "New password"
    },
    // PurchasePlansComponent: {
    //     screenName: "Plans&Pricing"
    // },
    ImageryPage: { //TODO burayı kontrol et. bilerek aynı screen name verilmiş olabilir.
        screenName: "Map"
    },
    AppStartPage: {
        screenName: "Splash"
    },
    AuthPage: {
        screenName: "Onboarding"
    },
    FieldImportComponent: {
        screenName: "Import"
    },
    FieldImportComponentStepSelection: {
        screenName: "Import-selection"
    },
    FieldImportComponentStepFailed: {
        screenName: "Import-failed"
    },
    FieldImportComponentStepNotFound: {
        screenName: "Import-not-found"
    },
    FieldImportComponentStepSuccess: {
        screenName: "Import-successfully"
    },
    ParcelInquiryComponent: {
        screenName: "Parcel inquiry"
    },
    ParcelInquiryComponentNotFind: {
        screenName: "Parcel inquiry-not find"
    },
    // ContactUsComponent: {
    //     screenName: "Contact Us"
    // }
}
/**
 * 
 export const SCREEN_NAME_BY_COMPONENT_NAME = {
    CU MapPage: {
        screenName: "Map" 
    },
    // Sil ExplorePage: {
    //     screenName: "ExplorePage"
    // },
    CU AddFieldPage: {
        screenName: "Adding Field Method"
    },
    CU AddFieldPageStepDrawing: {
        screenName: "Draw a field"
    },
    CU AddFieldPageStepDrawingSearch: {
        screenName: "Draw a field-search"
    },
    CU AddFieldPageStepDrawingStep2: {
        screenName: "Add Field Drawing-2"
    },
    CU AddFieldPageStepDrawingStep3: {
        screenName: "Add Field Drawing-3"
    },
    CU AddFieldPageStepDrawingStep4: {
        screenName: "Add Field Drawing-4"
    },
    CU AddFieldPageStepDrawingStep5: {
        screenName: "Add Field Drawing-5"
    },
    CU LoginComponent: {
        screenName: "Sign-in"
    },
    CU RegisterComponent: {
        screenName: "RegisterName"
    },
    CU RegisterComponentStep2: {
        screenName: "RegisterNumber"
    },
    CU RegisterComponentStep3: {
        screenName: "RegisterPassword"
    },
    // Sil FieldComparisonPage: {
    //     screenName: "FieldComparisonPage"
    // },
    CU ** IrrigationProgramPage: {
        screenName: "Irrigation"
    },
    // Sil ReferFriendPage: {
    //     screenName: "ReferFriendPage"
    // },
    // Sil ReferDetailsComponent: {
    //     screenName: "ReferDetailsComponent"
    // },
    CU SprayingHoursPage: {
        screenName: "Spraying hours"
    },
    CU SprayingDetailsComponent: {
        screenName: "Spraying hours-detail"
    },
    CU AddSprayingComponent: {
        screenName: "Spraying hours-Add date"
    },
    CU NewVraMapComponent: {
        screenName: "New VRA Map"
    },
    CU NewVraMapComponentDetail: {
        screenName: "VRA-details"
    },
    CU NewVraMapComponentAddDate: {
        screenName: "VRA-add a date"
    },
    CU NewVraMapComponentAddDateImage: {
        screenName: "VRA-add a date-image"
    },
    // Sil VraMapsPage: {
    //     screenName: "VRA Story"
    // },
    CU VraMapsEmptyPage: {
        screenName: "VRA-empty"
    },
    CU VraDetailsComponent: {
        screenName: "VRA-details-ready"
    },
    CU ** IrtemApplicationComponent:{
        screenName: "VRA-Irtem"
    },
    // Sil WeatherForecastPage: {
    //     screenName: "WeatherForecastPage"
    // },
    // Sil WeatherDetailComponent: {
    //     screenName: "WeatherDetailComponent"
    // },
    // Sil AccountSettingsPage: {
    //     screenName: "AccountSettingsPage"
    // },
    // Sil QuestionDetailsComponent: {
    //     screenName: "QuestionDetailsComponent"
    // },
    // Sil EngineerSupportPage: {
    //     screenName: "EngineerSupportPage"
    // },
    // Sil MyPlansPage: {
    //     screenName: "MyPlansPage"
    // },
    // Sil NotificationsComponent: {
    //     screenName: "NotificationsComponent"
    // },
    // Sil ProfileSettingsComponent: {
    //     screenName: "ProfileSettingsComponent"
    // },
    // Sil AccountPage: {
    //     screenName: "AccountPage"
    // },
    // Sil NotificationSettingsComponent: {
    //     screenName: "NotificationSettingsComponent"
    // },
    // Sil NotificationSettingsDetailComponent: {
    //     screenName: "NotificationSettingsDetailComponent"
    // },
    // Sil ComparePage: {
    //     screenName: "ComparePage"
    // },
    // Sil CompareFarmsComponent: {
    //     screenName: "CompareFarmsComponent"
    // },
    // Sil ComparisonResultComponent: {
    //     screenName: "ComparisonResultComponent"
    // },
    // Sil FaQuestionsComponent: {
    //     screenName: "FaQuestionsComponent"
    // },
    // Sil MyGuideComponent: {
    //     screenName: "MyGuideComponent"
    // },
    // Sil NewFeaturesComponent: {
    //     screenName: "NewFeaturesComponent"
    // },
    CU FarmDetailComponent: {
        screenName: "Field Detail"
    },
    // Sil NoteDetailModalComponent: {
    //     screenName: "NoteDetailModalComponent"
    // },
    CU MyFieldsPage: {
        screenName: "Fields"
    },
    CU ToolsPage: {
        screenName: "Tools"
    },
    CU ** ForgetPasswordComponent: {
        screenName: "Forgot password"
    },
    CU ForgetPasswordComponentSmsValidation: {
        screenName: "Enter Code"
    },
    CU ForgetPasswordComponentNewPassword: {
        screenName: "New password"
    },
    // Sil PurchasePlansComponent: {
    //     screenName: "Plans&Pricing"
    // },
    Sil ImageryPage: {
        screenName: "Map"
    },
    CU AppStartPage: {
        screenName: "Splash"
    },
    CU AuthPage: {
        screenName: "Onboarding"
    },
    CU FieldImportComponent:{
        screenName: "Import"
    },
    CU FieldImportComponentStepSelection:{
        screenName: "Import-selection"
    },
    CU FieldImportComponentStepFailed:{
        screenName: "Import-failed"
    },
    CU FieldImportComponentStepNotFound:{
        screenName: "Import-not-found"
    },
    CU FieldImportComponentStepSuccess:{
        screenName: "Import-successfully"
    },
    CU ParcelInquiryComponent:{
        screenName: "Parcel inquiry"
    },
    CU ParcelInquiryComponentNotFind:{
        screenName: "Parcel inquiry-not find"
    },
    Sil ContactUsComponent:{
        screenName: "Contact Us"
    }
}
 * 
 * 
 */