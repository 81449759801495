export interface IVraLogSaveModel {
	Data: Array<IVraLogSaveData>,
	GeoJson: string
}

export interface IVraLogSaveData {
	Status: VraLogStatuses,
	VraId: number,
	AppliedArea: number,
	SpendedAmount: number,
	VehicleSpeed: string,
	LatestPosition: {
		// accuracy: number
		// altitude: number
		// altitudeAccuracy: number
		// heading: number
		// latitude: number
		// longitude: number
		// speed: number
		Timestamp: string, //"2024-08-26T10:51:06.485Z",
		Coords: {
			Heading: number,
			Longitude: number,
			Latitude: number
		}
	},
	LogDate: string //"2024-08-26T10:51:06.485Z",
	LeftActuatorState: number,
	RightActuatorState: number,
	PathGeoJson?: any
	GeoJson?: any
}

export enum VraLogStatuses {
	START = 1695, // start
	STOP = 1696, // stop
	END = 1697, // end
	RUNNING = 1698 // Running
}