import { Injectable } from "@angular/core";
import { Storage } from "@ionic/storage-angular";
import { Observable } from "rxjs";
import { StorageKeys } from "../models/enums/storage-keys";
import { UnitKeys } from "../models/enums/unit-keys";
import { IAuthResponse } from "../models/interfaces/auth-response";
import { IGenericResponse } from "../models/interfaces/i-generic-response";
import { Preferences } from '@capacitor/preferences'
import { AppOfflineDetails } from "../models/classes/app-offline-details";

@Injectable({
    providedIn: "root"
})
export class StorageService {
    storage: Storage
    lang: string = ""
    deviceToken
    static DeviceToken: string
    static ApnsToken: string
    static UserInfo: IAuthResponse
    static AppOfflineStatus: AppOfflineDetails
    public static get isOnline(): boolean {
        if (StorageService.AppOfflineStatus)
            return !StorageService.AppOfflineStatus.IsOffline
        else
            return true
    }
    /**
     *
     */
    static IonicStorage: Storage
    constructor(private ionicStorage: Storage) {

    }

    static Set<T = any>(key: StorageKeys | UnitKeys, data: T): Promise<IGenericResponse<T>> {
        if (data == undefined || data == null)
            return Promise.reject({
                StatusCode: -1,
                IsSuccess: false,
                Message: "data_is_null",
                Data: null
            })
        return new Promise((resolve, reject) => {
            let retResponse: IGenericResponse<any> = {
                StatusCode: null,
                IsSuccess: null,
                Message: null,
                Data: null
            }
            Preferences.set({ key: key.toString(), value: JSON.stringify(data) }).then(response => {
                retResponse = {
                    StatusCode: 1,
                    IsSuccess: true,
                    Message: "",
                    Data: null
                }
                resolve(retResponse)
            }).catch(err => {
                retResponse = {
                    StatusCode: -1,
                    IsSuccess: false,
                    Message: "error_on_storage_save",
                    Data: err
                }
                reject(retResponse)
            }).finally(() => { })
        })
    }

    static Get<T = any>(key: StorageKeys | UnitKeys): Promise<IGenericResponse<T>> {
        if(key == undefined || key == null)
        return Promise.reject({
            StatusCode: -1,
            IsSuccess: false,
            Message: "key_is_null",
            Data: null
        })
        return new Promise((resolve, reject) => {
            let retResponse: IGenericResponse<any> = {
                StatusCode: null,
                IsSuccess: null,
                Message: null,
                Data: null
            }
            Preferences.get({ key: key.toString() }).then(_res => {
                let res = JSON.parse(_res.value)
                retResponse = {
                    StatusCode: res ? 1 : 0,
                    IsSuccess: res && true,
                    Message: "",
                    Data: res
                }
                resolve(retResponse)
            }).catch(err => {
                retResponse = {
                    StatusCode: -1,
                    IsSuccess: false,
                    Message: "error_on_storage_get",
                    Data: err
                }
                reject(retResponse);
            }).finally(() => {
            })
        })
    }

    static Remove(key: StorageKeys | UnitKeys): Promise<any> {
        return Preferences.remove({ key: key.toString() })
    }

    async set<T = any>(key: StorageKeys | UnitKeys, data: T): Promise<IGenericResponse<T>> {
        if (data == undefined || data == null)
            return Promise.reject({
                StatusCode: -1,
                IsSuccess: false,
                Message: "data_is_null",
                Data: null
            })
        return new Promise((resolve, reject) => {
            let retResponse: IGenericResponse<any> = {
                StatusCode: null,
                IsSuccess: null,
                Message: null,
                Data: null
            }
            Preferences.set({ key: key.toString(), value: JSON.stringify(data) }).then(response => {
                retResponse = {
                    StatusCode: 1,
                    IsSuccess: true,
                    Message: "",
                    Data: null
                }
                resolve(retResponse)
            }).catch(err => {
                retResponse = {
                    StatusCode: -1,
                    IsSuccess: false,
                    Message: "error_on_storage_save",
                    Data: err
                }
                reject(retResponse)
            }).finally(() => { })
        })
    }

    async get<T = any>(key: StorageKeys | UnitKeys): Promise<IGenericResponse<T>> {
        
        if (key == undefined || key == null)
            return Promise.reject({
                StatusCode: -1,
                IsSuccess: false,
                Message: "data_is_null",
                Data: null
            })
        return new Promise((resolve, reject) => {
            let retResponse: IGenericResponse<any> = {
                StatusCode: null,
                IsSuccess: null,
                Message: null,
                Data: null
            }
            Preferences.get({ key: key.toString() }).then(_res => {
                let res = JSON.parse(_res.value)
                retResponse = {
                    StatusCode: res == null || res == undefined ? 0 : 1,
                    IsSuccess: res == null || res == undefined ? false : true,
                    Message: "",
                    Data: res
                }
                resolve(retResponse)
            }).catch(err => {
                retResponse = {
                    StatusCode: -1,
                    IsSuccess: false,
                    Message: "error_on_storage_get",
                    Data: err
                }
                reject(retResponse);
            }).finally(() => {
            })
        })
    }

    remove(key: StorageKeys | UnitKeys): Promise<void> {
        return Preferences.remove({ key: key.toString() })
    }

/*
    static _Set<T = any>(key: StorageKeys | UnitKeys, data: T): Promise<IGenericResponse<T>> {
        return new Promise((resolve, reject) => {
            let response: IGenericResponse<any> = {
                StatusCode: null,
                IsSuccess: null,
                Message: null,
                Data: null
            }
            this.IonicStorage.set(key, data).then(res => {
                response = {
                    StatusCode: 1,
                    IsSuccess: true,
                    Message: "",
                    Data: null
                }
                resolve(response)
            }).catch(err => {
                response = {
                    StatusCode: -1,
                    IsSuccess: false,
                    Message: "error_on_storage_save",
                    Data: err
                }
                reject(response)
            }).finally(() => {

            })
        })
    }

    static _Get(key: StorageKeys) {
        return new Promise((resolve, reject) => {
            let response: IGenericResponse<any> = {
                StatusCode: null,
                IsSuccess: null,
                Message: null,
                Data: null
            }
            this.IonicStorage.get(key).then(res => {
                response = {
                    StatusCode: res ? 1 : 0,
                    IsSuccess: res && true,
                    Message: "",
                    Data: res
                }
                resolve(response)
            }).catch(err => {
                response = {
                    StatusCode: -1,
                    IsSuccess: false,
                    Message: "error_on_storage_get",
                    Data: err
                }
                reject(response);
            }).finally(() => {
            })
        })
    }
*/
    initStorage() {
        return new Observable(observer => {
            if (this.storage) {
                StorageService.IonicStorage = this.storage
                observer.next(this.storage);
                observer.complete();
            }
            else
                this.ionicStorage.create().then(response => {
                    this.storage = response;
                    StorageService.IonicStorage = this.storage
                    observer.next(this.storage);
                }).catch(err => {
                    observer.error(err);
                }).finally(() => {
                    observer.complete();
                })
        })
    }
}