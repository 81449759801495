import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { AuthPageRoutingModule } from './auth-routing.module';
import { AuthPage } from './auth.page';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'src/app/core/modules/shared.module';
import { NgxMaskModule } from 'ngx-mask';
// import { IntlPhoneInputModule } from "@doktar-yazilim/intl-phone-input";
import { environment } from "src/environments/environment";
import { RegisterComponent } from './components/register/register.component';
import { LoginComponent } from './components/login/login.component';
import { AuthModalComponent } from './components/auth-modal/auth-modal.component';
import { ExternalLoginComponent } from './components/external-login/external-login.component';
import { ForgetPasswordComponent } from './components/forget-password/forget-password.component';
import { SmsValidationComponent } from './components/sms-validation/sms-validation.component';
import { NewPasswordComponent } from './components/new-password/new-password.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { GuestRegisterModalComponent } from './components/guest-register-modal/guest-register-modal.component';
import { AlertModule } from 'src/app/core/modules/alert.module';
// import { IconModule } from 'src/app/core/modules/icon.module';
import { MaskitoModule } from '@maskito/angular';
import { SwiperModule } from 'swiper/angular';
import { ArpaInputComponent } from 'src/app/core/helper/components/arpa-input/arpa-input.component';
import { HtmlTranslatePipe } from 'src/app/core/pipes/custom-translate.pipe';
import { SelectLanguageComponent } from 'src/app/core/helper/components/select-language/select-language.component';
import { EnvironmentPipe } from 'src/app/core/pipes/environment.pipe';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    AuthPageRoutingModule,
    ReactiveFormsModule,
    TranslateModule,
    SharedModule,
    NgOtpInputModule,
    AlertModule,
    // IconModule,
    MaskitoModule,
    SwiperModule,
    ArpaInputComponent,
    HtmlTranslatePipe,
    SelectLanguageComponent,
    SharedModule,
    EnvironmentPipe
    // NgxMaskModule,
    // IntlPhoneInputModule.forRoot({
    //   countryListUrl: environment.countryList,
    //   // "assets/country-list.json",
    //   flagDirUrl: environment.countryFlags // './assets/flags/'
    // })
  ],

  declarations: [AuthPage,
    LoginComponent,
    RegisterComponent,
    AuthModalComponent,
    ExternalLoginComponent,
    ForgetPasswordComponent,
    SmsValidationComponent,
    NewPasswordComponent,
    GuestRegisterModalComponent
  ],

  exports: [AuthModalComponent,
    ExternalLoginComponent,
    ForgetPasswordComponent,
    SmsValidationComponent,
    NewPasswordComponent,
    GuestRegisterModalComponent,
    ArpaInputComponent
  ],

  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AuthPageModule { }
