import { NgModule, inject } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { GuestGuard } from './core/guards/guest.guard';
import { VraMapsService } from './core/services/vra-maps.service';
import { HasFarmGuard } from './core/guards/has-farm.guard';
import { AccountSettingsPage } from './pages/account-settings/account-settings.page';
import { NotificationSettingsComponent } from './pages/notification-settings/notification-settings.component';
import { AnalyticService } from './core/services/analytic.service';
import { SCREEN_NAME_BY_COMPONENT_NAME } from './core/constants/url-screenname.constant';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { AppOfflineService } from './core/services/app-offline.service';
import { OfflineGuard } from './core/guards/offline.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/app-start/app-start.module').then(m => m.AppStartPageModule)
  },
  {
    path: 'tabs',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule),
    canActivate: [AuthGuard, OfflineGuard]
  },
  {
    path: 'auth',
    loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthPageModule)
  },
  {
    path: 'add-field',
    loadChildren: () => import('./pages/add-field/add-field.module').then(m => m.AddFieldPageModule),
    canActivate:[OfflineGuard]
    // canActivate: [AuthGuard]
  },
  // {
  //   path: 'import-field',
  //   loadChildren: () => import('./pages/import-field/import-field.module').then(m => m.ImportFieldPageModule),
  //   canActivate: [AuthGuard]
  // },
  {
    path: 'weather',
    loadChildren: () => import('./pages/weather-forecast/weather-forecast.module').then(m => m.WeatherForecastPageModule),
    canActivate: [AuthGuard,OfflineGuard]
  },
  {
    path: 'notifications',
    loadChildren: () => import('./tabs/account/pages/notifications/notifications.module').then(m => m.NotificationsModule),
    canActivate: [AuthGuard, GuestGuard,OfflineGuard]
  },
  {
    path: 'refer-friend',
    loadChildren: () => import('./pages/refer-friend/refer-friend.module').then(m => m.ReferFriendPageModule),
    canActivate:[OfflineGuard]
  },
  {
    path: 'tabs/my-fields/detail/:id',
    loadChildren: () => import('./tabs/my-fields/components/farm-detail/farm-detail.module').then(m => m.FarmDetailComponentModule),
    canActivate:[OfflineGuard]
  },
  {
    path: "tabs/explore/my-guide",
    loadChildren: () => import("./tabs/explore/pages/my-guide/my-guide.module").then(m => m.MyGuideComponentModule),
    canActivate:[OfflineGuard]
  },
  {
    path: "tabs/explore/new-features",
    loadChildren: () => import("./tabs/explore/pages/new-features/new-features.module").then(m => m.NewFeaturesComponentModule),
    canActivate:[OfflineGuard]
  },
  {
    path: 'tabs/explore/faquestions',
    loadChildren: () => import("./tabs/explore/pages/fa-questions/fa-questions.module").then(m => m.FaQuestionsModule),
    canActivate:[OfflineGuard]
  },
  {
    path: "tabs/account/profile-settings",
    loadChildren: () => import("./pages/profile-settings/profile-settings.module").then(m => m.ProfileSettingsModule),
    canActivate:[OfflineGuard]
  },
  // {
  //   path: "tabs/account/engineer-support/detail",
  //   loadChildren: () => import("./tabs/account/pages/engineer-support/components/question-details/question-detail.module").then(m => m.QuestionDetailsModule)
  // },
  {
    path: "tabs/my-fields/note-detail",
    loadChildren: () => import("./tabs/my-fields/components/note-detail-modal/note-detail.module").then(m => m.NoteDetailModalModule),
    canActivate:[OfflineGuard]
  },
  {
    path: 'vra-maps',
    loadChildren: () => import('./pages/vra-maps/vra-maps.module').then(m => m.VraMapsPageModule),
    canDeactivate: [() => { VraMapsService.VraMapServiceInstance.clearVraList() }]
  },
  {
    path: 'spraying-hours',
    loadChildren: () => import('./pages/spraying-hours/spraying-hours.module').then(m => m.SprayingHoursPageModule),
    canActivate:[OfflineGuard]
  },
  {
    path: 'irrigation-program',
    loadChildren: () => import('./pages/irrigation-program/irrigation-program.module').then(m => m.IrrigationProgramPageModule),
    canActivate:[OfflineGuard]
  },
  {
    path: 'compare',
    loadChildren: () => import('./pages/compare/compare.module').then(m => m.ComparePageModule),
    canActivate:[OfflineGuard]
  },
  {
    path: 'bluetooth-test',
    loadChildren: () => import('./pages/bluetooth-test/bluetooth-test.module').then(m => m.BluetoothTestPageModule),
    canActivate:[OfflineGuard]
  },
  {
    path: 'purchase-plans',
    loadChildren: () => import('./pages/purchase-plans/purchase-plans.module').then(m => m.PurchasePlansPageModule),
    canActivate:[OfflineGuard]
  },
  {
    path: 'account-settings',
    component: AccountSettingsPage,
    loadChildren: () => import('./pages/account-settings/account-settings.module').then(m => m.AccountSettingsPageModule),
    canActivate:[OfflineGuard]
  },

  {
    path: 'notification-settings',
    loadChildren: () => import('./pages/notification-settings/notification-settings.module').then(m => m.NotificationSettingsModule),
    canActivate:[OfflineGuard]
  },
  {
    path: 'contact-us',
    loadChildren: () => import('./pages/contact-us/contact-us.module').then(m => m.ContactUsModule),
    canActivate:[OfflineGuard]
  },
  {
    path: 'engineer-support',
    loadChildren: () => import('./pages/engineer-support/engineer-support.module').then(m => m.EngineerSupportPageModule),
    canActivate:[OfflineGuard]
  },
  {
    path: 'offline-home',
    loadChildren: () => import('./pages/offline-home/offline-home.module').then(m => m.OfflineHomePageModule)
  },
  {
    path: 'account-offline-setup',
    loadChildren: () => import('./pages/account-offline-setup/account-offline-setup.module').then(m => m.AccountOfflineSetupPageModule),
    canActivate:[OfflineGuard]
  },
  // {
  //   path: 'field-comparison',
  //   loadChildren: () => import('./pages/field-comparison/field-comparison.module').then( m => m.FieldComparisonPageModule),
  //   canDeactivate: [() => {}]
  // }

];
@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
