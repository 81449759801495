import { HttpClient } from "@angular/common/http";
import { TranslateLoader, TranslateService } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { Observable, forkJoin, of } from "rxjs";
import { environment } from "src/environments/environment";

export class CustomTranslateLoader extends TranslateHttpLoader {

    /**
     * burada custom olarak translate dosyalarının çağırım işlemi yapılıyor.
     * istenilirse bu dosya içerisinde farklı dosyalar birleştirilebilir. 
     * böylece çoklu dosya kullanımı mümkün hale geliyor.
     * multi isimli regionların içini yorumdan satırından çıkararak özelliği açabilirsiniz
     * yoruma aldığınızda özellik kapanacaktır.
     */
    constructor(public http2: HttpClient, prefix2?: string, suffix2?: string) {
        super(http2, prefix2, suffix2)
    }

    override getTranslation(lang: string): Observable<Object> {
        // lang = "en";
        return new Observable(observer => {
            let forkFns: { original, add?, add2?} = {
                original: this.http2.get(this.prefix + lang + this.suffix + "?v=" + Math.random().toString())
            }
            /**
             * localde çalışırken yeni eklenecek keyleri farklı bir dosyada tutuyorum. onun çağırımı burada yapılıyor
             */
            //#region multi
            if (!environment.production) {
                forkFns["add"] = this.http2.get("./../../../../assets/i18n/sprint-20.json" + "?v=" + Math.random().toString())
            }
            //#endregion
            forkJoin(forkFns).subscribe({
                next: (res: { original, add?, add2?}) => {
                    /**
                     * localde çalışan farklı dosyanın mevcut dosyaya eklenmesi de bu aşamada yapılıyor.
                     * scope içerisindeki tüm kodun yorum satırını kaldırmanız yeterli.
                     */
                    //#region multi
                    if (res.add) {
                        let newLangs = res.add[lang]
                        if (newLangs)
                            Object.keys(newLangs).forEach(el => {
                                if (!res.original[el])
                                    res.original[el] = {}
                                Object.keys(newLangs[el]).forEach(el2 => {
                                    res.original[el][el2] = newLangs[el][el2]
                                })
                            })
                    }
                    //#endregion
                    observer.next(res.original);
                    observer.complete()
                    //#region multi
                    // } else {
                    //     observer.next(res.original);
                    //     observer.complete()
                    // }
                    //#endregion
                },
                error: err => {

                    if (err.status == 404) {
                        this.http2.get(this.prefix + "en" + this.suffix + "?v=" + Math.random().toString()).subscribe({
                            next: res2 => {
                                observer.next(res2);
                                observer.complete()
                            },
                            error: err2 => {
                                this.http2.get("../assets/i18n/" + lang + this.suffix + "?v=" + Math.random().toString()).subscribe({
                                    next: resLocal => {
                                        observer.next(resLocal);
                                        observer.complete()
                                    },
                                    error: err => {
                                        observer.error(err2)
                                        observer.complete()
                                    }
                                })
                            }
                        })
                    } else {
                        this.http2.get("../assets/i18n/" + lang + this.suffix + "?v=" + Math.random().toString()).subscribe({
                            next: resLocal => {
                                observer.next(resLocal);
                                observer.complete()
                            },
                            error: err => {
                                observer.error(err)
                                observer.complete()
                            }
                        })
                    }
                },
                complete: () => {
                    observer.complete()
                }
            })
        })
    }

}   