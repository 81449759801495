import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { StorageService } from './storage.service';
import { ClientBaseApiService } from '../api/client-base-api.service';
import { StorageKeys } from '../models/enums/storage-keys';
import { IGenericResponse } from '../models/interfaces/i-generic-response';
import { HttpRequestTypes } from '../models/enums/http-request-types';
import { Facebook } from "@awesome-cordova-plugins/facebook/ngx";
import { Capacitor } from '@capacitor/core';
import { App } from '@capacitor/app';
import { AccountService } from './account.service';
import { DoktarAppsFlyer } from './appsflyer.service';
import { CleverTapService } from './clevertap.service';
import { GoogleFirebaseAnalyticsService } from './google-firebase-analytics.service';
import { environment } from '../../../environments/environment';
import { AnalyticEvents } from '../models/enums/analytic-events';
import { CLICK_FUNCTION_TYPES } from '../directives/orbit-click.directive';
import { AppInsightsLoggingService } from './logging/logging.service';

// declare var UXCam: any;

@Injectable({
  providedIn: 'root'
})
export class AnalyticService {
  private isEventFired: boolean = false;
  appVersion = "3.2.4"

  adjustAllEventList = null;
  private _previousScreenUrl = "n/a"
  static SessionId = Date.now() + ""

  private _previousScreenName = "";
  public get previousScreenName() {
    return this._previousScreenName;
  }
  private set previousScreenName(value) {
    this._previousScreenName = value;
  }

  private _currentScreenName = "";
  public get currentScreenName() {
    return this._currentScreenName;
  }
  public set currentScreenName(value) {
    console.log("currentScreenName => ",value)
    if (value != this._currentScreenName) {
      this.previousScreenName = this._currentScreenName
      this.RecordeScreenView(value)
      this._currentScreenName = value;
    }
  }

  constructor(private clientApi: ClientBaseApiService, private googleFirebaseAnalyticsService: GoogleFirebaseAnalyticsService, private clevertapService: CleverTapService, private router: Router, private storageService: StorageService, private fb: Facebook) {
    if (Capacitor.isNativePlatform())
      App.getInfo().then(response => {
        this.appVersion = response.build
      })
  }

  trackNavigationEnd() {

    if (!this.isEventFired) {
      this.isEventFired = true;
      this.router.events
        .subscribe((event) => {
          if (event instanceof NavigationEnd) {
            let url = event.urlAfterRedirects && event.urlAfterRedirects !== '' ? event.urlAfterRedirects : event.url;
            this.storageService.get(StorageKeys.TOKEN).then(token => {
              if (token.IsSuccess && token.Data && token.Data !== '') {
                this.sendState(url).subscribe();
              }
              url = AnalyticService.ParseUrl(url);
            })
          }
        })
    }
  }

  static ParseUrl(propUrl): string {
    let rawSplittedUrl = propUrl.split("?");
    let rawUrlParams = rawSplittedUrl[0]
    let rawQueryParams = rawSplittedUrl[1]
    if (rawQueryParams) {
      /**
       * /compare/result/31_32
       */
      let exceptionArray = ["tempfarmattid", "q=", "id", "cropname", "cropId"] //currentpage
      let splittedValue: Array<string> = rawQueryParams.split("&").map(el => {
        if (exceptionArray.some(t => el.toLowerCase().includes(t.toLowerCase()))) {
          let splitedSubEl = el.split("=")
          splitedSubEl[1] = splitedSubEl[0]
          el = splitedSubEl.join("=")
        }
        return el
      })
      splittedValue = splittedValue.filter(t => t !== '')
      if (splittedValue.length > 0) {
        {
          splittedValue.sort()
          rawSplittedUrl[1] = splittedValue.join("&")
        }
        // return rawSplittedUrl[0] + "?" + newQueryString;
      } else {
        // return rawSplittedUrl[0]
      }
    }
    let splittedUrlParams = rawUrlParams.split("/")
    splittedUrlParams = splittedUrlParams.map(el => {
      if (el !== '' && Number.isInteger(+el)) {
        el = "itemid"
      }
      return el
    })
    rawSplittedUrl[0] = splittedUrlParams.join("/");
    rawSplittedUrl = rawSplittedUrl.filter(t => t !== '')
    return rawSplittedUrl.join("?")
  }

  sendState(stateName: string, desc?: string): Observable<IGenericResponse> {
    return new Observable(observer => {
      const serviceUrl = environment.baseApiUrl + `send/state/status?stateName=${stateName}`;
      this.clientApi.baseApiCall({ url: serviceUrl, payload: null, requestType: HttpRequestTypes.POST }).subscribe({
        next: res => {
          observer.next(res);
        },
        error: err => {
          let error = {
            IsSuccess: false,
            Message: err.message,
            Data: null,
            StatusCode: -11
          } as IGenericResponse
          observer.next(error);
          return;
        },
        complete: () => {
          observer.complete();
        }
      });
    });
  }


  public logTapEvent(ev, elRef, possibleOutcome: CLICK_FUNCTION_TYPES, id) {
    // console.clear()
    let eventName = "Tap"
    let props = {
      "Element_ID": id ? id : elRef.nativeElement && elRef.nativeElement.id ? elRef.nativeElement.id : "unknown-element-id",
      "Timestamp": Date.now(),
      "Screen_Name": this.currentScreenName, //elRef.nativeElement.ownerDocument.location.pathname,
      "User_ID": AccountService.instance.userData ? AccountService.instance.userData?.UserRoleId : "N/A",
      "Session_ID": AnalyticService.SessionId,
      "Event_Type": ev.type,//elRef.nativeElement.type,
      "Element_Type": elRef.nativeElement.tagName,
      "Referrer_URL": this.previousScreenName, //"n/a", //TODO buraya previousPage datası gelecek
      "Outcome": possibleOutcome,
      "User_Profile": AccountService.instance.userData ? JSON.stringify(AccountService.instance.userData.getBasicProfileInfo()) : null, // clever tap primitive type istiyor.
      "Element_Text": elRef.nativeElement.textContent
    }
    if (!environment.production)
      props["traffic_type"] = "internal"
    console.log("tap => ", props)
    if (!Capacitor.isNativePlatform())
      return;
    this.fb.logEvent(eventName, props).then(res => {
      console.log("facebook event: " + eventName + " => " + JSON.stringify(res))
    }).catch(err => {
      console.log("facebook event error: " + eventName + " => " + JSON.stringify(err))
    })
    this.clevertapService.recordEvent(eventName, props)
    // try {
    //   UXCam.logEvent(eventName)
    // } catch (error) {

    // }

    AppInsightsLoggingService.logEvent(eventName, props);
    DoktarAppsFlyer.LogEvent(eventName, props);
    let firebaseparams = {
      content_type: "custom_page_event"
    }
    if (props && Object.keys(props).length > 0) {
      Object.keys(props).forEach(el => {
        firebaseparams[el] = props[el]
      })
    }
    this.googleFirebaseAnalyticsService.sendEvetLog(eventName, firebaseparams)
  }

  public logEvent(rawState: AnalyticEvents, props?) {
    let eventName = rawState //AnalyticService.ProcessStateName(rawState);

    // if (AccountService.IsGuest) {
    //   eventName += "_guest_user"
    // }
    if (Capacitor.isNativePlatform()) {
      if (!environment.production)
        props["traffic_type"] = "internal"
      this.fb.logEvent(eventName, props).then(res => {
        console.log("facebook event: " + eventName + " => " + JSON.stringify(res))
      }).catch(err => {
        console.log("facebook event error: " + eventName + " => " + JSON.stringify(err))
      })
      let firebaseparams = {
        content_type: "custom_page_event"
      }
      if (props && Object.keys(props).length > 0) {
        Object.keys(props).forEach(el => {
          firebaseparams[el] = props[el]
        })
      }
      this.googleFirebaseAnalyticsService.sendEvetLog(eventName, firebaseparams)
      // FirebaseAnalytics.logEvent({
      //   name: eventName,
      //   params: firebaseparams
      // }).then(res => {

      // })
      this.clevertapService.recordEvent(eventName, props)
      // try {
      //   UXCam.logEvent(eventName)
      // } catch (error) {

      // }

      AppInsightsLoggingService.logEvent(eventName, props);
      DoktarAppsFlyer.LogEvent(eventName, props);
    } else {
      console.info("logevent", eventName, props)
    }
  }

  platform = Capacitor.getPlatform()
  public purchaseEventLog({ product, value, currency, transaction = null, params = {} }) {
    try {
      this.fb.logPurchase(value, currency, params).then((res) => {
        console.log("fb purchase", res)
      }).catch(err => {
        console.log("fb purchase", err)
      })
      if (currency == 'N/A')
        currency = "unknown"

      this.clevertapService.recordPurchase({ product, value, currency, transaction }, [params])
    } catch (error) {

    }
  }

  public customPurchaseEventLogs({ TransactionID, ItemSold, Quantity, PaymentMode, Amount, FieldName, Currency, CustomerType }) {
    let props = {
      UserID: AccountService.instance.userData ? AccountService.instance.userData?.UserRoleId : "N/A",
      sessionID: AnalyticService.SessionId,
      Timestamp: Date.now(),
      TransactionID,
      ItemSold,
      Quantity,
      PaymentMode,
      Amount,
      CustomerType,
      Currency,
      FieldName,
      ReferrerScreen: this.previousScreenName
    }
    if (!environment.production)
      props["traffic_type"] = "internal"
    this.logEvent(AnalyticEvents.CHARGED, props)

  }


  async RecordeScreenView(screenName: string) {
    if (Capacitor.isNativePlatform()) {
      if (!screenName || screenName == '')
        return
      let props = {
        "Screen_Name": screenName,
        TimeStamp: Date.now() + "",
        "User_ID": AccountService.instance.userData ? AccountService.instance.userData.UserRoleId : "N/A",
        "Session_ID": AnalyticService.SessionId,
        "Referrer_Screen": this.previousScreenName
      }
      if (!environment.production)
        props["traffic_type"] = "internal"
      // try {
      //   UXCam.tagScreenName(screenName)
      // } catch (error) {

      // }
      try {
        this.googleFirebaseAnalyticsService.setScreenName(screenName)
      } catch (error) {

      }
      try {
        this.clevertapService.recordScreen(screenName)
      } catch (error) {

      }
      this.googleFirebaseAnalyticsService.sendEvetLog(AnalyticEvents.SCREEN_VIEW, props)
      this.clevertapService.recordEvent(AnalyticEvents.SCREEN_VIEW, props)
      DoktarAppsFlyer.LogEvent(AnalyticEvents.SCREEN_VIEW, props);
      const appInsightProps = {
        "User_ID": props.User_ID,
        "Session_ID": props.Session_ID,
        "Referrer_Screen": props.Referrer_Screen
      }
      const url = location.pathname + location.search //location.href.split(location.host)
      AppInsightsLoggingService.logPageView(props.Screen_Name, url, appInsightProps, new Date(props.TimeStamp))
    } else {
      let props = {
        "Screen_Name": screenName,
        TimeStamp: Date.now() + "",
        UserName: AccountService.NameSurname || "",
        "User_ID": AccountService.instance.userData ? AccountService.instance.userData.UserRoleId : "N/A",
        "Session_ID": AnalyticService.SessionId
      }
      console.log(AnalyticEvents.SCREEN_VIEW, props)
    }
  }

  static ProcessStateName(prop) {
    let newstateName = prop + "";
    // let replaceRegEx = /\//gi&&/\./gi;
    if (!newstateName || newstateName === '') {
      newstateName = "tabs/map".replace(/\./gi, "_").replace(/\//gi, "_").replace(/\-/gi, "").replace(/\=/gi, "__").replace(/[&.*+?^${}()|[\]\\]/g, '_');
      return newstateName + "_v3";
    } else {
      if (newstateName.startsWith("/"))
        newstateName = newstateName.slice(1)
      let parsedState = newstateName.split("?");
      if (parsedState.length > 1) {
        parsedState = parsedState.map(el => {
          let tempValues = el.split("&")
          tempValues = tempValues.filter(t => !t.toLowerCase().includes("q="))
          if (tempValues.length > 0)
            el = tempValues.join("&");
          else
            el = "unknown";
          return el
        })
        if (parsedState.filter(t => t).length > 0)
          newstateName = parsedState.filter(t => t).join("?");
        else
          newstateName = parsedState.join("");
        newstateName = newstateName.replace(/\./gi, "_").replace(/\//gi, "_").replace(/\-/gi, "").replace(/\=/gi, "__").replace(/[&.*+?^${}()|[\]\\]/g, '_')
        if (newstateName.startsWith("_"))
          newstateName = newstateName.replace("_", "");
        return newstateName + "_v3";
      } else {
        return newstateName.replace(/\./gi, "_").replace(/\//gi, "_").replace(/\-/gi, "").replace(/\=/gi, "__").replace(/[&.*+?^${}()|[\]\\]/g, '_') + "_v3";
      }
    }
  }

}
