import { Injectable } from "@angular/core";
import { ModalOptions } from "@ionic/angular";
import { ComponentRef } from "@ionic/core";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs";
import { SelectLanguageComponent } from "../helper/components/select-language/select-language.component";
import { IAppConstantsLanguageOptions } from "../models/interfaces/app-constant-lang-opts";
import { ModalService } from "./modal.service";
import { AppConstantsService } from "./app-constants.service";
import { StorageService } from "./storage.service";
import { StorageKeys } from "../models/enums/storage-keys";
import { DateTimeService } from "./date-time.service";

@Injectable({
    providedIn: "root"
})
export class LanguageService {

    static CountryCodeFromIP = ""
    static CurrentLangCode: string = "en"
    static currentLanguage: IAppConstantsLanguageOptions = { key: 'ENGLISH', lang: 'English', defaultIso2: 'gb', code: 'en', flag: '' } //{ key: 'TURKISH', lang: 'Türkçe', defaultIso2: 'tr', code: 'tr', flag: '' }
    langOpts: Array<IAppConstantsLanguageOptions>
    constructor(private translateService: TranslateService, private storageService: StorageService, public modalService: ModalService, private appConstantsService: AppConstantsService) {
    }

    public getLangOpts(): Observable<Array<IAppConstantsLanguageOptions>> {
        if (Array.isArray(this.langOpts) && this.langOpts.length > 0) {
            return new Observable(observer => {
                observer.next(this.langOpts);
                observer.complete();
            })
        } else {
            return new Observable(observer => {
                this.appConstantsService.GetAppConstants<IAppConstantsLanguageOptions[]>(true, "languageOptions").subscribe(constRepsonse => {
                    this.langOpts = constRepsonse;
                    observer.next(this.langOpts);
                    observer.complete();
                })
            })
        }
    }

    // /**
    //  * use instant of TranslateService
    //  * @param key 
    //  * @param props 
    //  * @returns string
    //  */
    // public getTranslation<T = string | Array<string> | any>(key: string | string[], props?: any): T {
    //     return this.translateService.instant(key, props);
    // }

    public getBrowserLang(): string {
        return this.translateService.getBrowserLang()
    }

    public getcurrentLanguage = () => {
        return LanguageService.currentLanguage
    }

    public setCurrentLang(lang: string): void {
        LanguageService.CurrentLangCode = lang
        this.translateService.use(lang).subscribe(res => {
            if (res) {
                let _resLang = res.lang.default.toLowerCase()
                if (lang !== _resLang)
                    lang = _resLang
                if (this.langOpts) {
                    LanguageService.currentLanguage = this.langOpts.find(t => t.code == lang)
                    DateTimeService.LocaleCode = this.translateService.instant("lang.culture_info")
                    this.changeTextDirection(LanguageService.currentLanguage)
                } else {
                    //bazı hatalar, daha önce bu burada olmadığı için olmuş olabilir.
                    this.getLangOpts().subscribe(response => {
                        LanguageService.currentLanguage = this.langOpts.find(t => t.code == lang)
                        DateTimeService.LocaleCode = this.translateService.instant("lang.culture_info")
                        this.changeTextDirection(LanguageService.currentLanguage)
                    })
                }
                this.storageService.set(StorageKeys.LANG, lang).then()
            }
        })
    }

    public openOptionsMenu() {
        this.modalService.presentModal({ component: SelectLanguageComponent, componentProps: {}, options: { initialBreakpoint: 1, cssClass: "modal-radius modal-lang" } as ModalOptions<ComponentRef> }).then(response => {

        })
    }

    public dismissOptionsModal(id: number, data?, role?) {
        this.modalService.dismissByModalId(id, data, role).then(respnose => {

        })
    }

    changeTextDirection(lang){
        var htmlDoc = document.getElementsByTagName("html")[0]
        if(lang && lang.code.includes("ar")){
            htmlDoc.setAttribute("dir","rtl");
        }else{
            htmlDoc.setAttribute("dir","ltr");
        }
    }
}