import { Injectable } from "@angular/core";
import { IonicSafeString, ToastController } from "@ionic/angular";
import { IToastModel } from "../models/interfaces/toast-model";
import { Router } from "@angular/router";

@Injectable({
    providedIn: "root"
})
export class ToastService {
    toasts: Array<any> = []

    /**
     *
     */
    constructor(private toastController: ToastController, private router: Router) {

    }

    async PresentBasicToast(props: IToastModel) {
        if (props.class) {
            let classList = props.class.split(" ")
            classList.push("orbit-custom")
            props.class = classList.join(" ")
        } else
            props.class = "orbit-custom"
        const toast = await this.toastController.create({
            message: new IonicSafeString(props.message),
            duration: props.duration || 3000,
            mode: "md",
            position: props.position ? props.position : "bottom",
            translucent: true,
            icon: props.icon,
            cssClass: props.class,
            color: props.type?.valueOf()
        });
        setTimeout(() => {
            if (this.router.url.includes("/tabs/")) {
                let style = toast.shadowRoot.firstChild["style"].transform
                style = style.replace("-8px", "-96px")
                toast.shadowRoot.firstChild["style"].transform = "translateY(-96px)"
                toast.shadowRoot.firstChild["style"].transition = "500ms transform ease";
            }
        }, 100);
        await toast.present()
        return toast;
    }

    async PresentToastWithCallback(props: IToastModel) {
        if (props.class) {
            let classList = props.class.split(" ")
            classList.push("orbit-custom")
            props.class = classList.join(" ")
        } else
            props.class = "orbit-custom"

        const toast = await this.toastController.create({
            message: new IonicSafeString(props.message),
            duration: props.duration || 3000,
            mode: "md",
            position: props.position ? props.position : "bottom",
            translucent: true,
            icon: props.icon,
            cssClass: props.class,
            color: props.type?.valueOf(),
            buttons: [{
                role: props.buttonRole || "cancel",
                side: props.iconSide || "end",
                icon: props.buttonIcon || "close",
                handler: () => {
                    if (props["callback"])
                        props.callback()
                }
            }]
        });
        setTimeout(() => {
            if (this.router.url.includes("/tabs/")) {
                let style = toast.shadowRoot.firstChild["style"].transform
                style = style.replace("-8px", "-96px")
                toast.shadowRoot.firstChild["style"].transform = "translateY(-96px)"
                toast.shadowRoot.firstChild["style"].transition = "500ms transform ease";
            }
        }, 100);
        await toast.present()
        return toast;
    }


}