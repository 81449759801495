import { Injectable } from '@angular/core';
import { AppOfflineDetails, IOfflinePageListModel } from '../models/classes/app-offline-details';
import { StorageService } from './storage.service';
import { StorageKeys } from '../models/enums/storage-keys';
import { VraMapApiService } from '../api/vra-map-api.service';
import { IVraResponse } from '../models/interfaces/vra-map-response';
import { FarmService } from './farm.service';
import VraItem from './class/vra-item';
import { MediaService } from './media.service';
import { Observable } from 'rxjs';
import { Capacitor } from '@capacitor/core';
import { VraZoneColors } from 'src/app/pages/vra-maps/components/vra.constants';

@Injectable({ providedIn: 'root' })
export class AppOfflineService {


    private static _AppOfflineDetails: AppOfflineDetails = new AppOfflineDetails();

    public static get isOnline(): boolean {
        if (AppOfflineService.AppOfflineDetails)
            return !AppOfflineService.AppOfflineDetails.IsOffline
        else
            return true
    }

    public static get AppOfflineDetails(): AppOfflineDetails {
        if (!AppOfflineService._AppOfflineDetails) {
            AppOfflineService._AppOfflineDetails = new AppOfflineDetails()
        }
        return AppOfflineService._AppOfflineDetails;
    }
    public static set AppOfflineDetails(value: AppOfflineDetails) {
        StorageService.AppOfflineStatus = value
        AppOfflineService._AppOfflineDetails = value;
    }
    offlinePageDetails: AppOfflineDetails

    constructor(private storageService: StorageService, private mediaService: MediaService, private vraMapApiService: VraMapApiService, private farmService: FarmService) { }

    async terminateOfflineSession() {
        return new Promise((resolve, reject) => {
            AppOfflineService.AppOfflineDetails = new AppOfflineDetails(null, [], false);
    
            Promise.all([this.storageService.remove(StorageKeys.APP_OFFLINE_STATUS), this.storageService.remove(StorageKeys.APP_OFFLINE_DATA)]).then(res => {
                resolve(true)
            }).catch(err => {
                reject(false)
            })
        })
    }

    async initOfflineStatus() {

        AppOfflineService.AppOfflineDetails = new AppOfflineDetails(null, [], false);
        try {
            var response = await this.storageService.get<AppOfflineDetails>(StorageKeys.APP_OFFLINE_STATUS)
            if (response.IsSuccess && response.Data) {
                var tempData = new AppOfflineDetails(
                    response.Data.StartDate,
                    response.Data.SelectedOfflinePages,
                    response.Data.IsOffline
                );
                AppOfflineService.AppOfflineDetails = tempData
                this.offlinePageDetails = tempData
                return AppOfflineService.AppOfflineDetails
            } else {

            }
        } catch (error) {
            return AppOfflineService.AppOfflineDetails
        }
    }

    async setOfflineStatusDetail(offlinePageList: string[]): Promise<boolean> {
        var data: AppOfflineDetails = new AppOfflineDetails();
        data.StartDate = new Date();
        data.IsOffline = true;
        data.SelectedOfflinePages = offlinePageList
        try {
            var setResult = await this.storageService.set(StorageKeys.APP_OFFLINE_STATUS, data);
            AppOfflineService.AppOfflineDetails = data;
            return setResult.IsSuccess;
        } catch (error) {
            return false
        }
    }


    public getOfflineModuleDataFromServer(module) {

        switch (module) {
            case "vra-maps":
                return this._getListForVra.bind(this)
                break;

            default:
                var emptyFn = () => Promise.reject("empty")
                return emptyFn
                break;
        }
    }

    offlineSelectionPageList: Array<IOfflinePageListModel> = []
    private listRawData: Array<any> = []
    private _getListForVra() {
        return new Promise((resolve, reject) => {
            this.vraMapApiService.GetVraListRequest({}).subscribe({
                next: response => {
                    if (response.IsSuccess) {
                        this.listRawData = response.Data
                        this.offlineSelectionPageList = response.Data.map(t => {
                            return {
                                Id: t.Id,
                                FarmName: t.FarmName,
                                TempFarmAttId: t.TempFarmAttId,
                                Date: t.CreatedOn,
                                Details: `${t.VraTypeName} - ${t.ProductName}`
                            }
                        })
                        resolve(true)
                    } else {
                        reject("data-not-found")
                    }
                },
                error: err => {
                    reject("error-on-request");
                }
            })
        })
    }


    public async setOfflineVraItemsData(idList: number[]) {
        return new Promise((resolve, reject) => {
            var items = (this.listRawData as Array<IVraResponse>).filter(t => idList.some(a => a == t.Id))
            var vraItemList = []

            this.farmService.GetAllFarms({}).subscribe(async res => {
                for (var i = 0; i < items.length; i++) {
                    var el = items[i]

                    var vraItem = new VraItem()
                    vraItem.farm = this.farmService.allFarms.find(a => a.TempFarmAttId == el.TempFarmAttId).farm
                    vraItem.buildItem(el)
                    /**
                     * 
                     * TODO: zone pngleri cihaza indirilecek +
                     * TODO: tif dosyaları cihaza indirilecek +
                     * TODO: image ve tifin harita üzerine yüklenmesi için çalışma yapılacak. base64 yüklenebiliyordur umarım
                     * TODO: onlar için bir get request yapısı hazırlanacak direkt olarak al kullanın yanında. mevcut yükleme içerisine yedirilmesi lazım.
                     * 
                     * 
                     */
                    vraItem.OfflineZoneImages = {}
                    if (Capacitor.isNativePlatform()) {
                        for (var j = 3; j <= 7; j = j + 2) {
                            let vraImage = `${vraItem.VraImageUrl}`.replace("!zoneid", j.toString());
                            let tiffImage = `${vraImage}`.replace(".png", ".tif"); // "./../../../../../assets/Tobacco_Polygon.tif" // 
                            let pngName = vraImage.split("/").reverse()[0].split("?")[0]
                            let tifName = tiffImage.split("/").reverse()[0].split("?")[0]

                            const pngUri = await this.mediaService.saveFileToDevice(vraImage, pngName)
                            const tifUri = await this.mediaService.saveFileToDevice(tiffImage, tifName)
                            console.log(`files saved ${j} => ${pngUri}, ${tifUri}`)
                            vraItem.OfflineZoneImages[j] = {
                                png: pngUri,
                                tiff: tifUri
                            }
                        }
                    }
                    vraItem.LastChoosePercentage = vraItem.LastChoosePercentage == null || vraItem.LastChoosePercentage == undefined ? 50 : vraItem.LastChoosePercentage
                    vraItem.LastChooseZone = vraItem.LastChooseZone || 3
                    var getVraRateFn = () => new Promise((resolve, reject) => {
                        this.vraMapApiService.getFertilizerRate(vraItem.Id, vraItem.LastChoosePercentage).subscribe({
                            next: next => {
                                vraItem.Zones = next.Data.Zones.map(t => {
                                    t.ZoneList.forEach(el => {
                                        el.Color = VraZoneColors["zones" + t.ZoneNumber][el.OrderNumber - 1]
                                    })
                                    return t;
                                })
                                resolve(true);
                            },
                            error: err => resolve(true)
                        })
                    })
                    await getVraRateFn()

                    vraItemList.push(vraItem);
                }
                console.log("bitti");
                resolve(vraItemList);
            })
        })
    }

}