import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AppOfflineService } from '../services/app-offline.service';
import { offlineEnvironment } from '../constants/offline-environment';

@Pipe({
    name: 'environment',
    standalone: true
})

export class EnvironmentPipe implements PipeTransform {

    transform(value: string, ...args: any[]): any {
        if (value !== null || value !== undefined) {
            if (AppOfflineService.isOnline)
                return environment[value]
            else
                return offlineEnvironment[value]
        }
        else
            return null
    }
}