import { Injectable } from "@angular/core";
import { Capacitor } from "@capacitor/core";
import { Platform } from "@ionic/angular";
import { Observable } from 'rxjs'

@Injectable()
export class UtilsService {
    /**
     *
     */
    constructor(private platform: Platform) {

    }

    height: number

    static DeviceDetailsInfo: string

    getDeviceHeight(isHeaderVisible: boolean = false): number {
        this.setDeviceHeight();
        if (isHeaderVisible) {
            this.height = this.height - 44;
        }
        if (Capacitor.getPlatform().toLowerCase() === 'ios')
            this.height = this.height - 20;
        return this.height;
    }

    setDeviceHeight() {
        this.height = this.platform.height();
    }

    /**
     * 
    /**
     * 
     * @param isSusccess : boolean
     * @param timeout : number
     * @returns isSuccess === true ? next : error
     * her durumda complete tetiklenir.
     * 300ms default bekleme süresi vardır.
     */
    public static EmptyObservableRequest<T = any>(isSusccess: boolean, timeout: number = 300): Observable<T> {
        if (timeout == null || timeout == undefined || timeout < 0)
            timeout = 300;
        const response: T = new Object() as T
        return new Observable<T>(observer => {
            setTimeout(() => {
                if (isSusccess)
                    observer.next(response)
                else
                    observer.error();
                observer.complete()
            }, timeout);
        })
    }

    public static invertColor(prop: string) {
        var hex = ""
        if (prop.startsWith("rgb")) {
            let array = prop.split("(")[1].split(")")[0].split(", ")
            hex = array.map(t => {
                let _temp = +t;
                let strTemp = _temp.toString(16)
                return strTemp.length === 2 ? strTemp : `0${strTemp}`
            }).join("")
        }
        if (prop.indexOf('#') === 0) {
            hex = prop.slice(1);
        }
        // convert 3-digit hex to 6-digits.
        if (hex.length === 3) {
            hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
        }
        if (hex.length !== 6) {
            throw new Error('Invalid HEX color.');
        }
        // invert color components
        var r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
            g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
            b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);
        // pad each with zeros and return
        return '#' + UtilsService.padZero(r) + UtilsService.padZero(g) + UtilsService.padZero(b);
    }

    public static BlackAndWhiteColor(prop: string) {
        var hex = ""
        if (prop.startsWith("rgb")) {
            let array = prop.split("(")[1].split(")")[0].split(", ")
            hex = array.map(t => {
                let _temp = +t;
                let strTemp = _temp.toString(16)
                return strTemp.length === 2 ? strTemp : `0${strTemp}`
            }).join("")
        }
        if (prop.indexOf('#') === 0) {
            hex = prop.slice(1);
        }
        // convert 3-digit hex to 6-digits.
        if (hex.length === 3) {
            hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
        }
        if (hex.length !== 6) {
            throw new Error('Invalid HEX color.');
        }

        var r = (parseInt(hex.slice(0, 2), 16)),
            g = (parseInt(hex.slice(2, 4), 16)),
            b = (parseInt(hex.slice(4, 6), 16));
        var val = Math.floor(0.2126 * r + 0.7152 * g + 0.0722 * b) * 2 >= 255 ? "00" : "FF"
        return '#' + UtilsService.padZero(val) + UtilsService.padZero(val) + UtilsService.padZero(val);
    }

    private static padZero(str, len = 2) {
        // len = len || 2;
        var zeros = new Array(len).join('0');
        return (zeros + str).slice(-len);
    }

    /**
     * 
     * @param color1 = 'FF0000' 
     * @param color2 = '00FF00' 
     * @param ratio = 0.5 
     * @returns 
     */
    public static CalculateColorPercentage(color1: string, color2: string, ratio: number) {
        // var color1 = 'FF0000';
        // var color2 = '00FF00';
        // var ratio = 0.5;
        color1 = color1.replace("#", "")
        color2 = color2.replace("#", "")
        var hex = function (x) {
            x = x.toString(16);
            return (x.length == 1) ? '0' + x : x;
        };

        var r = Math.ceil(parseInt(color1.substring(0, 2), 16) * ratio + parseInt(color2.substring(0, 2), 16) * (1 - ratio));
        var g = Math.ceil(parseInt(color1.substring(2, 4), 16) * ratio + parseInt(color2.substring(2, 4), 16) * (1 - ratio));
        var b = Math.ceil(parseInt(color1.substring(4, 6), 16) * ratio + parseInt(color2.substring(4, 6), 16) * (1 - ratio));

        var middle = hex(r) + hex(g) + hex(b);
        return "#" + middle
    }
    public static RandomColor() {
        const randomColor = Math.floor(Math.random() * 16777215).toString(16);
        return "#" + randomColor;
    }

    public static async GetUserIpInfo() {
        try {
            let geolocationFetch = await fetch("https://freeipapi.com/api/json")
            if (geolocationFetch && geolocationFetch.ok) {
                let data = await geolocationFetch.json()
                return { ip: data.IPv4, location: `${data.city}, ${data.countryCode}`, coords: `${data.latitude},${data.longitude}` }
            } else
                throw new Error("geolocation url broken")
        } catch (error) {
            let ipApiFetch = await fetch("http://www.ip-api.com/json")
            if (ipApiFetch && ipApiFetch.ok) {
                let data = await ipApiFetch.json()
                return { ip: data.query, location: `${data.city}, ${data.country}`, coords: `${data.lat},${data.lon}` }
            }
            else
                return null
        }

    }
}