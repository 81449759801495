import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { SplashScreen } from "@capacitor/splash-screen";
// import { SplashScreen } from "@capacitor/splash-screen";
import { TranslateService } from "@ngx-translate/core";
import { StorageKeys } from "../models/enums/storage-keys";
import { AccountService } from "./account.service";
import { LanguageService } from "./lang.service";
import { LocationService } from "./location.service";
import { StorageService } from "./storage.service";
// import * as SentryAngular from "@sentry/angular-ivy";
// import * as Sentry from "@sentry/capacitor";


export function OnAppInit(appinit: AppInitService) {
    SplashScreen.hide()
    // var sentryFn = async () => {
    //     Sentry.init(
    //         {
    //           dsn: "https://564c561c4dd24328b0d2556a226b0df0@o248275.ingest.sentry.io/5196300",

    //           // Set your release version, such as "getsentry@1.0.0"
    //           release: "orbitGetSentry@3.1.8",
    //           // Set your dist version, such as "1"
    //           dist: "<dist>",
    //         //   integrations: [
    //         //     {
    //         //         name: "",
    //         //         setupOnce: (eventProcesser, getCurrentHub) => {
    //         //             eventProcesser()
    //         //             getCurrentHub()
    //         //         }
    //         //     }
    //         //   ],
    //         //   integrations: [
    //         //     // Registers and configures the Tracing integration,
    //         //     // which automatically instruments your application to monitor its
    //         //     // performance, including custom Angular routing instrumentation
    //         //     new SentryAngular.BrowserTracing({
    //         //       tracePropagationTargets: ["localhost", "https://yourserver.io/api"],
    //         //       routingInstrumentation: SentryAngular.routingInstrumentation,
    //         //     }),
    //         //   ],
    //           // Set tracesSampleRate to 1.0 to capture 100%
    //           // of transactions for performance monitoring.
    //           // We recommend adjusting this value in production
    //           tracesSampleRate: 1.0,
    //         },
    //         // Forward the init method from @sentry/angular
    //         SentryAngular.init
    //       );
    // }
    // try {
    //     sentryFn()
    // } catch (error) {

    // }
    // SplashScreen.show({
    //     autoHide: false,
    //     fadeInDuration:0,
    //     fadeOutDuration: 1500
    // }).then()
    return () => {
        return new Promise((resolve, reject) => {
            // appinit.storageService.initStorage().subscribe(res => {
            LocationService.GetLocationFromIp().then(res => {
                if (res)
                    LanguageService.CountryCodeFromIP = res.countryCode.toLowerCase()
                // resolve(true)
            })
            appinit.storageService.get<string>(StorageKeys.LANG).then(langResponse => {
                if (langResponse && langResponse.IsSuccess && langResponse.Data !== '') {
                    LanguageService.CurrentLangCode = langResponse.Data
                    appinit.translateService.use(langResponse.Data).subscribe(resp => {
                        resolve(true)
                    }, err => {
                        resolve(true)
                    })
                } else {
                    let tempLang = appinit.translateService.getBrowserLang()
                    console.log("browser lang => ", tempLang)
                    let langList = ["tr", "en", "es", "fr", "it", "pt", "ro", "bg", "ru", "uk"]
                    let lang = langList.some(t => t == tempLang) ? tempLang : "en"
                    LanguageService.CurrentLangCode = lang
                    appinit.translateService.use(lang).subscribe(resp => {
                        resolve(true)
                    }, err => {
                        resolve(true)
                    })
                    // this.langService.setCurrentLang(this.langService.getBrowserLang());
                }
            }).catch(err => {
                resolve(true)
            })

        })
    }
}



@Injectable({ providedIn: 'root' })
export class AppInitService {
    constructor(public router: Router, public storageService: StorageService, public translateService: TranslateService) { }

}
