import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";
import { BackButtonDirective } from "../directives/back-button.directive";
// import { CustomEventLoggerDirective } from "../directives/custom-event-logger.directive";
import { ImageLoadDirective } from "../directives/image-loading.directive";
import { AltBaseModal } from "../helper/class/alt-base-modal.component";
import { BackButtonComponent } from "../helper/components/back-button/back-button.component";
import { GenericListModalComponent } from "../helper/components/generic-list-modal/generic-list-modal.component";
import { ListModalComponent } from "../helper/components/list-modal/list-modal.component";
import { ModalBaseComponent } from "../helper/components/modal-base/modal-base.component";
import { UserAgreementComponent } from "../helper/components/user-agreement/user-agreement.component";
import { IsFormValidPipe } from "../pipes/is-form-valid.pipe";
import { BaseModalModule } from "./base-modal.module";
// import { IconModule } from "./icon.module";
import { PullupItemListModalComponent } from "../helper/components/pullup-item-list-modal/pullup-item-list-modal.component";
// import { EnvironmentPipe } from "../pipes/environment.pipe";
import { InputKeypressListenerDirective } from "../directives/input-keypress-listener.directive";
import { OrbitIconsPipe } from "../pipes/orbit-icons.pipe";
import { OrbitClickDirective } from "../directives/orbit-click.directive";
import { RouterLinkListenerDirective } from "../directives/router-link-listener.directive";

@NgModule({
    imports: [
        IonicModule,
        FormsModule,
        CommonModule,
        TranslateModule,
        // IconModule,
        BaseModalModule
    ],

    declarations: [
        BackButtonComponent,
        OrbitIconsPipe,
        // SelectLanguageComponent,
        GenericListModalComponent,
        ListModalComponent,
        // TutorialTemplateComponent
        IsFormValidPipe,
        BackButtonDirective,
        UserAgreementComponent,
        // CustomEventLoggerDirective,
        ImageLoadDirective,
        // ModalBaseComponent,
        AltBaseModal,
        PullupItemListModalComponent,
        // EnvironmentPipe,
        InputKeypressListenerDirective,
        OrbitClickDirective,
        RouterLinkListenerDirective
    ],

    exports: [
        BackButtonComponent,
        OrbitIconsPipe,
        // SelectLanguageComponent,
        GenericListModalComponent,
        ListModalComponent,
        // TutorialTemplateComponent
        IsFormValidPipe,
        BackButtonDirective,
        UserAgreementComponent,
        // CustomEventLoggerDirective,
        ImageLoadDirective,
        // ModalBaseComponent,
        AltBaseModal,
        PullupItemListModalComponent,
        // EnvironmentPipe,
        InputKeypressListenerDirective,
        OrbitClickDirective,
        RouterLinkListenerDirective
    ],
    schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule {

}