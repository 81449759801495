export enum StorageKeys {
    TOKEN = "token",
    BEARER = "bearer",
    IS_REGISTERED = "is_registered",
    IS_FIRST_LOGIN = "is_first_login",
    USER = "ulr",
    LANG = "lang",
    PERMISSIONS = "permissions",
    DEVICE_TOKEN = "device_token",
    DEVICE_TOKEN_CONTROL = "device_token_control",
    NOTIFICATION_STATUS = "notification_status",
    PHONE_CODE = "phone_code",
    DARK_MODE = "dark_mode",
    IMPORT_INTRO = "import_intro",
    IRRIGATION_INTRO = "irrigation_intro",
    IRRIGATION_RAIN_STATUS = "irrigation_rain_status",
    TUTORIAL_LAST_SHOWED = "tutorial_last_showed",
    MY_LATEST_LOCATION = "my_latest_location",
    PAGE_ONBOARDINGS = "page_onboardings",
    FACEBOOK_USER = "fbUser",
    GOOGLE_USER = "googleUser",
    APPLE_USER = "appleUser",
    LOGIN_COUNT = "login_count",
    APPLE_LOGIN_DATA = "apple_login_data",
    SESSION_INFOS = "session_info",
    MAPBOX_LOCATION_FEATURE = "mapbox_location_feature",
    APP_OFFLINE_STATUS = "app_offline_status",
    APP_OFFLINE_DATA = "app_offline_data"
}

export enum PageNames {
    COMPARE = "compare",
    ACCOUNT = "account",
    VRA = "vra"
}